import { ThanksTxt, NumberTxt, ConfirmEmailTxt, LinkYellow, LinkPurple } from '../styles/Cart';
import { BaseFrame100vh, CenterDiv } from '../styles/Containers'
import bg from "../assets/imgs/bg_purchase.png";
import { useContext, useEffect } from 'react';
import { CartContext } from '../components/CartContext';
import GetCookie from '../hooks/GetCookie';

export const AcceptedPurchase = () => {
  const cart = useContext(CartContext)
  
  useEffect(() => {
    cart.emptyCart();
  }, [])
  

  let dataset = {
    "Number": GetCookie("orderID")
  }
  return (
  <BaseFrame100vh img={bg}>
      <CenterDiv>
          <div style={{textAlign:"center"}}>
              <ThanksTxt>Thanks for your purchase!</ThanksTxt>
              <NumberTxt>Your order number is #{dataset.Number}</NumberTxt>
              <ConfirmEmailTxt>you will receive an email with the confirmation</ConfirmEmailTxt>
              <LinkYellow className="yellow"><a href="mailto:shop.galeriamaxo@gmail.com">Contact Us</a></LinkYellow><br/><br/>
              <LinkPurple className="purple" to="/shop">Continue shopping</LinkPurple>
          </div>
      </CenterDiv>
  </BaseFrame100vh>
  )
}
