import styled from "styled-components";
import { maxDevice, minDevice } from "./devices";

export const DivCart = styled.div`
    background-color: rgba(0, 0, 0, 0.6);
    width:70vw;
    max-height:70vh;
    max-width:1120px;
    padding:15px clamp(1px, 10vw, 100px);
    position:relative;

    @media ${maxDevice.miniLaptop} {
        overflow-y: scroll;
        max-height:60vh;

    }
`

export const DivCartNoPad = styled.div`
    background-color: rgba(0, 0, 0, 0.6);
    width:70vw;
    max-height:70vh;
    max-width:1120px;
    position:relative;
    padding:0;
    margin:0;

    @media ${maxDevice.tablet} {
        width:95vw;
        //max-height:95vw;
    }

`

export const DivCartNoOverflow = styled.div`
    overflow-x:hidden;
    max-height:55vh;
    padding:15px clamp(1px, 10vw, 100px);
    margin:0;

    @media ${maxDevice.tablet} {
        padding:15px clamp(1px, 7vw, 100px);
    }

`

export const TextField = styled.input`
    background: #44474c;
    font-family: HipelessBrush;
    letter-spacing: 4px;
    color:white;
    border:none;
    width:100%;
    margin: 0 0 2vh 0;
    padding:3px 5px;
    font-size:2.25vh;

    @media ${maxDevice.miniLaptop} {
        font-size:1.85vh;
        margin: 0 0 0.75vh 0;
    }
`

export const TextAreaField = styled.textarea`
    background: #44474c;
    font-family: HipelessBrush;
    letter-spacing: 4px;
    color:white;
    border:none;
    width:100%;
    margin: 0 0 2vh 0;
    padding:3px 5px;
    font-size:2.25vh;

    @media ${maxDevice.miniLaptop} {
        font-size:1.85vh;
        margin: 1.25vh 0 0.75vh 0;
    }
`

export const Dropdown = styled.select`
    background: #44474c;
    font-family: HipelessBrush;
    letter-spacing: 4px;
    color:white;
    border:none;
    width:100%;
    margin: 0 0 15px 0;
    padding:3px 5px;
    font-size:2.25vh;

    @media ${maxDevice.miniLaptop} {
        font-size:1.85vh;
        margin: 0 0 0.75vh 0;
    }
`

export const Opt = styled.option`
    background: #44474c;
    font-family: HipelessBrush;
    letter-spacing: 4px;
    font-size:1.75vh;
`

export const ButtonPillIg = styled.button`
    background-color : white;
    font-family: HipelessBrush;
    font-size:0.75em;
    border: none;
    color:#c54def;
    width:80%;
    height:70%;
    margin:0;
    border-radius:50px;
`

export const Arrow = styled.div`
    position:absolute;
    bottom:-55px;
    right:-40px;
    background:none;
    border :none;
    font-family: HipelessBrush;
    font-size:1.1em;
    color:white;
    letter-spacing: 4px;

    @media ${maxDevice.miniLaptop} {
        position:relative;
        bottom:0px;
        right:0;
        /* scale: 0.6; */
        text-align: right;
        /* width:130%; */
        padding-top: 5px;
    }
`

export const ArrowImg = styled.img`
    
    @media ${maxDevice.miniLaptop} {
        height: 4vh;
    }
`
