import { Box, Flex } from "grid-styled";
import { Infos, Price, SubTitle, Title } from "../../styles/Cart";
import CheckBox from "./CheckBox";
import Bin from '../../assets/imgs/Bin.png';
import { useContext } from "react";
import { CartContext } from "../CartContext";
import { Link } from "react-router-dom";
import { countType } from "../Getters";
import { CenterDiv } from "../../styles/Containers";
import GetCookie from "../../hooks/GetCookie";

const ItemList = ({dataset}) => {
    const cart = useContext(CartContext);
    let paintings = 0
    return(
      dataset.map(
        (data) => (<div style={{position: "relative"}}>
            <span style={{display:"none"}}>
            {data.Art === "paintings" && countType('paintings', {dataset}) == 2
            ? paintings += 1 
            :""
            }
            </span>
            <Flex style={{padding:"30px 0"}}>
            <Box width={2/13}>
              <img width="100%" src={data.Picture}></img>
            </Box>
            <Box style={{paddingLeft:"30px"}} width={6/10}>
              <Title>
                {data.Title}
              </Title>
              <SubTitle>
                {data.SubTitle}
              </SubTitle>
              <Infos>
                {data.Size}
              </Infos>
              <Infos>
                {data.Type}
              </Infos>
            </Box>
            <Box width={2/10} style={{textAlign:"center"}}>
                <CenterDiv>
                {data.Art !== "paintings"
                ? <Link onClick={() => cart.rmOneFromCart(data.Id)}>-&nbsp;</Link>
                : ""
                }
                {cart.getQuantity(data.Id)}
                {data.Art !== "paintings"
                ? <Link onClick={() => cart.addCart(data.Id)}>&nbsp;+</Link>
                : ""
                }
                </CenterDiv>
              {/* <div style={{transform : "scale(0.4)", position:"absolute", bottom:"0px"}}>
                {(data.Option != "" ? 
                <CheckBox txt={data.Option} id={data.Id} checked={data.Frame}/> 
                : "")}
              </div> */}
              {/* Checkbox Opt */}
            </Box>
            <Box width={2/10}>
              <div style={{position:"relative", height:"100%"}}>
                <Price>
                  {
                    paintings == 2 && data.Art === "paintings" && GetCookie("offer25")
                    ? <>
                    {data.Price * 0.75}€<br/>
                    <span style={{fontSize:"75%", textDecoration:"line-through", color:"#c54def"}}>
                    {data.Price}€
                    </span>
                    <span style={{fontSize:"75%", color:"#c54def"}}>
                    &nbsp;-25%
                    </span>
                    </>
                    : (data.Price * cart.getQuantity(data.Id))+"€"
                  }
                  {/* <div style={{fontSize:"50%"}}>
                    {data.Price + "€/u"}
                  </div> */}
                </Price>
                <Link href="" onClick={() => cart.deleteFromCart(data.Id)} style={{position: "absolute", bottom:"0px", right:"0px"}}>
                  <img style={{height:"25px"}} src={Bin}/>
                </Link>
              </div>
            </Box>
          </Flex>
        </div>)
      )
    )
  }

  export default ItemList;