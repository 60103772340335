import { useEffect } from "react";
import { Link } from "react-router-dom";
import { CenterDiv } from "../../../styles/Containers";
import { ArtistProfile, CircularPhoto } from "../../../styles/Homepage";
import CircleText from "./CircleText";
import ShopButton from "./ShopButton";


const Profile = ({artist}) => {

    return (
      <Link className="white" to={artist[2]}>
        <ArtistProfile st={artist[4]} style={{position:"relative"}}>
          <CenterDiv>
            <CircularPhoto id={artist[0]} src={artist[1]}/>
          </CenterDiv>
          <CenterDiv>
            <CircleText artistName={artist[0]} fontSize={artist[3]}/>
          </CenterDiv>
          <CenterDiv>
            <ShopButton/>
          </CenterDiv>
        </ArtistProfile>
      </Link>
    )
  }

  export default Profile;