import React from 'react'
import { Box } from 'grid-styled'
import ig from '../../assets/imgs/insta2.png';
import { ContainerTxt, Flex2, Icon } from '../../styles/Contact'
import { IgPic, IgText } from '../../styles/Overlay';

const Social = ({txt, link, img, customStyle}) => {
    
    return (
        <ContainerTxt style={{margin:"10px 0",padding:"0"}}>
            <a className='yellow' target="_blank" href={link || '#'}>
              <Flex2 style={{height:"5.5vh"}}>
                <Box>
                  <IgText>{txt}</IgText>
                </Box>
                <Box width="1vw">
                  
                </Box>
                <Box width="">
                  {img && <Icon src={img || ig}/>}
                  {!img && <IgPic /> }
                </Box>
              </Flex2>
            </a>
          </ContainerTxt>
    )
}

export default Social
