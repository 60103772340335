import {
    InfoArt, 
    SubTitleArt, 
    TitleArt,
    ButtonAdd,
    ButtonBuy
} from '../../styles/Shop.js';
import { BaseFrame100vh, CenterDiv } from '../../styles/Containers';
import bg from "../../assets/imgs/shop_background.png"
import { Link, useLocation, useParams } from 'react-router-dom';
import { getArticle, getArticleAsync } from '../../components/Getters.jsx';
import CheckBox from '../../components/Desktop/CheckBox.jsx';
// import { CheckBoxShipping } from '../Payment.jsx';
import Magnifier from 'react-magnifier';
import { useContext } from 'react';
import { CartContext } from '../../components/CartContext.jsx';
import { useState } from 'react';
import { useEffect } from 'react';


export const ShopArticleM = () => {
    const { param } = useParams();

    const loc = useLocation();

    const cart = useContext(CartContext); 

    const {id} = useParams();
    const {type} = useParams();
    const [addedOpt, setaddedOpt] = useState(false)

    const [dataset, setdataset] = useState([])

    const Art = async () => {
        setdataset(await getArticleAsync(id))
      }

      useEffect(() => {
          Art()
      }, [])

    let opt = (dataset.Option != "" ? <CheckBox id={dataset.Id} txt={dataset.Option} /> : <div style={{height:"90px"}}></div>);

    // console.log(cart.items)
  return (
    <BaseFrame100vh style={{overflow:"hidden"}} img={bg}>
        <CenterDiv>
            <div style={{textAlign:"center"}}>
                <CenterDiv style={{position:"relative", margin:"0 0px", width:"100vw"}}>
                    <div style={{maxWidth:"70vw"}}>
                        <Magnifier mgTouchOffsetX={50} zoomFactor={3} mgHeight={85} mgWidth={85} style={{maxHeight:"35vh", maxWidth:"65vw", paddingBottom:"10px", width:"auto", zIndex:"10"}} src={dataset.Picture} />
                    </div>
                    <div style={{rotate:"-90deg", width:"auto", height:"90vw", position:"absolute", display:"flex", flexDirection:"column", justifyContent:'flex-end', zIndex:"", pointerEvents: "none"}}>
                        <InfoArt style={{fontSize:"1.6vh"}}>{dataset.Size} - {dataset.Type}</InfoArt>
                        {/* <InfoArt style={{fontSize:"3vh"}}>{dataset.Price}€</InfoArt> */}
                    </div>
                </CenterDiv>
                
                <div>
                    <TitleArt style={{fontSize:"8vmin", maxWidth:"100vw"}}>{dataset.Title}</TitleArt>
                    {/* {dataset.SubTitle !== "" && <SubTitleArt style={{fontSize:"6vmin", maxWidth:"95vw"}}>{dataset.SubTitle}</SubTitleArt>} */}
                    <SubTitleArt style={{fontSize:"6vmin", maxWidth:"100vw"}}>{dataset.Price}€</SubTitleArt>
                    {/* <form> */}
                        <input name="data" type='hidden' value={JSON.stringify(dataset)}></input>
                        {
                            cart.getQuantity(dataset.Id) == 0
                            ?<>
                            {/* <br/> */}
                            {/* {dataset.Option !== '' && dataset.Art === "paintings"
                            ? addedOpt === false
                                ? <Link onClick={() => {cart.addCart(0); setaddedOpt(true)}}>Add a frame for 195€</Link>
                                : <span style={{fontSize:"4vmin"}}>A frame was added to your cart<br/></span>
                            : ""} */}
                                {/* <CenterDiv style={{height:"60px"}}>
                                    <div style={{width:"340px", transform:"scale(0.7)"}}>
                                        <div style={{textAlign:"center", width:"0"}}>{opt}</div>
                                    </div>
                                </CenterDiv> */}
                                <ButtonAdd style={{fontSize:"3vw"}} onClick={() => cart.addCart(id)} type="button" value="Add to cart"></ButtonAdd><br/>
                                {/* <br/> */}
                                {/* <ButtonBuy style={{fontSize:"3vw"}} type="submit" value="Go Back"></ButtonBuy> */}
                            </>
                            : (
                                dataset.Art == "paintings"
                                ? <div style={{fontSize:"3vh"}}>Already added to cart</div>
                                : <div style={{fontSize:"3vh"}}>
                                    Already added to cart<br/><br/>
                                    <Link onClick={() => cart.rmOneFromCart(dataset.Id)}>-&nbsp;</Link>
                                    {cart.getQuantity(dataset.Id)}
                                    <Link onClick={() => cart.addCart(dataset.Id)}>&nbsp;+</Link>
                                </div>
                            )
                        }
                        
                        {
                        dataset.Art === ("collection")  || dataset.Art === ("giftcards") || dataset.Art === ("special")
                        ? <Link to="./../../">
                            <ButtonBuy style={{fontSize:"3vw"}} className="yellow" type="button" value="Go Back"></ButtonBuy>
                        </Link>
                        :<Link to="./..">
                            <ButtonBuy style={{fontSize:"3vw"}} className="yellow" type="button" value="Go Back"></ButtonBuy>
                        </Link>
                        }

                    {/* </form> */}
                </div>
            </div>
        </CenterDiv>
    </BaseFrame100vh>
  )
}



export default ShopArticleM;