import Masonry from "react-masonry-css"
import ShopTile from "../../pages/ShopTile";
import { getArticlesByCat, getPanelData, getPanelDataAsync } from "../Getters";
import { useParams } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from "react";

const ShopItems = (filters) => {
    const breakpointColumnsObj = {
        default: 4,
        1386: 3,
        1100: 2,
        480 : 1
      };

      const [Collection, setCollection] = useState("")
      const [Keyword, setKeyword] = useState("")
      const [Artist, setArtist] = useState("")

      const {type} = useParams();

      let q = {
        type: type,
        collection: filters.Collection,
        keywords: filters.Keyword,
        artist: filters.Artist
      } 

      // const panelData = getPanelData(q);
      const [panelData, setpanelData] = useState([])

      let generatePanels = new Array();

      const AllArt = async () => {
        // setpanelData(await getPanelDataAsync(q))
        setpanelData(await getArticlesByCat(type))
      }



      useEffect(() => {
          AllArt()

      }, [panelData])

      panelData.forEach(data => {
        generatePanels.push(<ShopTile data={data}/>)
      });
      
      
      

      //just sort

      // const [generatePanels, setgeneratePanels] = useState([])

      // useEffect(() => {
      //   const panelData = getPanelData(q);

      //   // generatePanels([])

      // panelData.forEach(data => {
      //   generatePanels.push(<ShopTile data={data}/>)
      // });

      // }, [q])
  return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonryGrid"
            columnClassName="masonryGridColumn shiva">
            {generatePanels}
        </Masonry>
  )
}

export default ShopItems