import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import { SliderDiv } from '../../styles/Containers.js';
import slide1 from '../../assets/imgs/slider/1.jpg'
import slide2 from '../../assets/imgs/slider/2.jpg'
import slide3 from '../../assets/imgs/slider/3.jpg'
import slide4 from '../../assets/imgs/slider/4.jpg'
import slide5 from '../../assets/imgs/slider/5.jpg'
import slide6 from '../../assets/imgs/slider/6.jpg'
import slide7 from '../../assets/imgs/slider/7.jpg'
import slide8 from '../../assets/imgs/slider/8.jpg'

const GallerySlider = () => {
    return (
        <div style={{height:'100vh', width:'100vw', backgroundColor:'black'}} className="slider-hand">
            <Swiper
            slidesPerView={1}
            loop={true}
            modules={[Autoplay]}
            autoplay={{delay: 3000}}
            >
                <SwiperSlide>
                    <SliderDiv src={slide1}>
                    </SliderDiv>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDiv src={slide2}>
                    </SliderDiv>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDiv src={slide3}>
                    </SliderDiv>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDiv src={slide4}>
                    </SliderDiv>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDiv src={slide5}>
                    </SliderDiv>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDiv src={slide6}>
                    </SliderDiv>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDiv src={slide7}>
                    </SliderDiv>
                </SwiperSlide>
                <SwiperSlide>
                    <SliderDiv src={slide8}>
                    </SliderDiv>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default GallerySlider
