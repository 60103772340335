import React from 'react'
import { BreadcrumbsMain, BreadNumber, BreadNumberActive, BreadTextUnder } from '../../styles/Cart'
import stripe from "../../assets/imgs/stripe3.png"
import { CenterDiv } from '../../styles/Containers'


const Breadcrumbs = (number) => {
    let numbr = [
        [1, false, "position:absolute; left:0px;", "top:70px; left:-150px;"], 
        [2, false, "", "top:110px; left:50px;"], 
        [3, false, "position:absolute; right:0px;", "top:70px; right:-150px;"]
    ] //nombre, active, position of the number, position of the text

    numbr[(number.number[0]-1)][1] = (number.number[1]);
    //marche que si on est relative


    let bread = numbr.map((num) => (
      num[1]!=false 
      ? <BreadNumberActive add={num[2]}>
          {/* <BreadTextUnder add={num[3]}>
            {num[1]}
          </BreadTextUnder> */}
          <CenterDiv style={{paddingLeft:"2px"}}>
            {num[0]}
          </CenterDiv>
        </BreadNumberActive> 
      : <BreadNumber add={num[2]}>
          <CenterDiv style={{paddingLeft:"2px"}}>
            {num[0]}
          </CenterDiv>
        </BreadNumber>
      ));
    

  return (
    <BreadcrumbsMain>
        <CenterDiv style={{overflow:"hidden"}}>
            <img style={{position:"absolute"}} src={stripe}></img>
            {bread}
        </CenterDiv>
    </BreadcrumbsMain>
  )
}

export default Breadcrumbs