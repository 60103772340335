import React, { useState } from 'react'
import { MiniCartDiv } from '../../styles/MiniCart'
import MiniInfoCart from './MiniInfoCart';
import { countType, getCart, getCartAsync, getPrice, getSentence } from '../Getters';
import { Bar, Marketting, Price, Title } from '../../styles/Cart';
import { ContinueArrowDiv } from './ContinueArrowDiv';
import { Link } from 'react-router-dom';
import {CartContext} from '../CartContext'
import {useContext} from 'react'
import { useEffect } from 'react';
import MediaQuery from 'react-responsive/';
import { size } from '../../styles/devices';
// 48.44


const MiniCart = ({opened}) => {
  const cart = useContext(CartContext);
  // console.log(cart.items)
  // const dataset = getCart(cart.items);

  const [dataset, setdataset] = useState([])

  const AllArt = async () => {
    setdataset(await getCartAsync(cart.items))
  }

  useEffect(() => {
      AllArt()
  }, [cart.items])
  
  return (   
    <MiniCartDiv style={{transform:"translate("+ (opened?"0%":"100%") +")"}}>
        <div style={{width:"100%", overflowY:"scroll"}}>
          
          <Title style={{color:"white", textAlign:"center", paddingRight:"11vh"}}>Your Cart</Title>
          {
            dataset.length === 0
            ? <span><MediaQuery maxWidth={972}><br></br></MediaQuery>Your cart is empty, <Link to="/shop">Go to shop</Link></span>
            : <MiniInfoCart style={{padding:0}} dataset={dataset}/>
          }
          <Bar/>
          <Price style={{textAlign:"right", fontSize:"3vh"}}>
            {/* <Marketting style={{position:"relative"}}>
              {getSentence(countType('paintings', {dataset}))}
            </Marketting> */}
            Total : { getPrice({dataset}, 0)[1] }€
          </Price>
        </div>
        <div style={{position:"relative", right:20, }}>
          <div style={{position:"absolute", top:10, right:20}}>
              {
              dataset.length !== 0
              ? <Link className='yellow' to="/cart/checkout">
                <ContinueArrowDiv className='yellow' txt="Checkout"/>
              </Link>
              : ""
              }
          </div>
        </div>
    </MiniCartDiv>
  )
}

export default MiniCart