import PageText from "./PageText"
import Social from "./Social"

const TxtContact = () => {
    return(
        <>
          <PageText title='Queries related to purchase:' text='shop.galeriamaxo@gmail.com' can='purple' link='mailto:shop.galeriamaxo@gmail.com'/>
          <PageText title='Artworks inquiries & collaborations:' text='galeriamaxo@gmail.com' can='purple' link='mailto:galeriamaxo@gmail.com'/>
          <PageText title='Internships & jobs:' text='mgaleriamaxo@gmail.com' can='purple' link='mailto:mgaleriamaxo@gmail.com'/>
          <PageText title='Call us:' text='+34 931 87 68 44' link='tel:+34931876844' can='purple'/>

          {/* <Social txt="Call us" link='tel:+34931876844' img={call}/> */}
          <Social txt="Follow us" link='https://www.instagram.com/galeria_maxo/'/>
        </>
  )
}

export default TxtContact