import {
  GalleryTop, 
  GalleryPic, 
  GalleryBottom, 
  GalleryDiv, 
  Map, 
  BARCELOCA, 
  DivMap, 
  FlexMap, 
  Yellow, 
  Pinned, 
  SeeMore
} from '../../../styles/Homepage.js';
import bg from "../../../assets/imgs/hp_background_f4.png";
import {BaseFrame100vh, BaseFrame1078px, CenterDiv} from '../../../styles/Containers';
import MapImg from '../../../assets/imgs/map.png';
import {Box} from 'grid-styled';
import SignBarceloca from '../../../assets/imgs/barceloca.gif';
import Pin from '../../../assets/imgs/Pin.png';
import P1 from '../../../assets/imgs/Galerias/P1.png';
import Petritxol from '../../../assets/imgs/Galerias/Petritxol.png';
import PortalNou from '../../../assets/imgs/Galerias/PortalNou.png';
import Carders from '../../../assets/imgs/Galerias/Carders.png';
import Olles from '../../../assets/imgs/Galerias/Olles.png';
import Princesa from '../../../assets/imgs/Galerias/Princesa.png';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const DetailsGallery = ({Gallery}) => { //DON'T FORGET THE F******* BRACKETS
  return(
    <GalleryDiv style={{backgroundImage:{P1}}}>
      <GalleryTop>
        <GalleryPic src={Gallery.Picture}>

        </GalleryPic>
      </GalleryTop>
      <GalleryBottom>
        <span style={{fontSize:"1em"}}>
          - {Gallery.Name} -<br/>
        </span>
        <span style={{fontSize:"0.85em"}}>
          {Gallery.Adress}<br/>
         </span>
        <span style={{fontSize:"0.75em"}}>
          {Gallery.Adress2}
        </span>
      </GalleryBottom>
    </GalleryDiv>
  )
}

const FindUs = () => {
  const defaultPin = 
  {
    "Name": "Name",
    "Adress":"Adress",
    "Adress2":"08002, Barcelona, Spain",
    "Picture":"",
    "default":true
  }

  const pins = 
    [
      {
        "Name": "Galeria Maxó Petrixol",
        "Adress":"Carrer de Petritxol, 18",
        "Adress2":"08002, Barcelona, Spain",
        "Picture":Petritxol,
        "PosX": 51,
        "PosY": 20.5,
        "Link":"https://goo.gl/maps/FjA5rY1nrHkX7co56"
      },
      {
        "Name": "Galeria Maxó Pi 1",
        "Adress":"Career Del Pi, 1",
        "Adress2":"08002, Barcelona, Spain",
        "Picture":P1,
        "PosX": 52,
        "PosY": 24.2,
        "Link":"https://goo.gl/maps/JYagAHrNCbBKiUMy9"
      },
      {
        "Name": "Galería Maxó Portal Nou",
        "Adress":"Carrer del Portal Nou, 29",
        "Adress2":"08003, Barcelona, Spain",
        "Picture":PortalNou,
        "PosX": 18,
        "PosY": 42.8,
        "Link":"https://goo.gl/maps/Ew4K6xJKA9UokwC87"
      },
      {
        "Name": "Galería Maxó Carders",
        "Adress":"Carrer dels Carders, 29",
        "Adress2":"08003, Barcelona, Spain",
        "Picture":Carders,
        "PosX": 28,
        "PosY": 41.5,
        "Link":"https://goo.gl/maps/Uss5C5w74QbdGPBa6"
      },
      {
        "Name": "Galería Maxó Olles",
        "Adress":"Plaça de les Olles, 1",
        "Adress2":"08003, Barcelona, Spain",
        "Picture":Olles,
        "PosX": 48,
        "PosY": 49.8,
        "Link":"https://goo.gl/maps/8Up9acuhifuQnsEr8"
      },
      {
        "Name": "Galería Maxó Princesa",
        "Adress":"Carrer del Princesa, 15",
        "Adress2":"08003, Barcelona, Spain",
        "Picture":Princesa,
        "PosX": 34,
        "PosY": 44.5,
        "Link":"https://goo.gl/maps/1hiaQYRWnV6yoLjX7"
      }
    ]
  ;

  const [pinHover, setPinHover] = useState(defaultPin);

  const pinsList = pins.map((pin) =>
    <a className='black' target="_blank" href={pin.Link}>
      <Pinned
      src={Pin} 
      id={pin.Name} 
      style={{
        top:pin.PosX+"%", 
        left:pin.PosY+"%"
      }}
      onMouseEnter={() => setPinHover(pin)}
      onMouseLeave={() => setPinHover(defaultPin)}
      />
    </a>
  );

  return ( 
    <BaseFrame100vh stl="background-position: center top" img={bg} id="Frame4">
      <CenterDiv>
        <Link className='yellow' to="/about#Places"><SeeMore>See more...</SeeMore></Link>
        <DivMap>
          <BARCELOCA src={SignBarceloca}/>
          {pinsList}
          {!(pinHover.default) && <DetailsGallery Gallery={pinHover}/>}
          <Map src={MapImg}/>
          <FlexMap>
            <Box width={1/2}>
              <Yellow>Open everyday</Yellow>
              <span>12:00 - 20:00</span>
            </Box>
            <Box width={1/2}>
              <Yellow>Phone</Yellow>
              <span>+34 931 87 68 44</span>
            </Box>
          </FlexMap>
        </DivMap>
      </CenterDiv>
    </BaseFrame100vh>
  )
}

export default FindUs
