import styled from "styled-components";
import {maxDevice} from "../styles/devices.js"

export const MiniCartDiv = styled.div`
    position: fixed;
    top:0;
    right: 0;
    width: 28vw;
    height: 100vh;
    z-index: 3000;
    background-color: rgba(0,0,0,0.95);
    transition: ease 0.8s;
    padding: 3vh;
    overflow-x: scroll;
    overflow-y: scroll;

    @media ${maxDevice.miniLaptop}{
        width: 90vw;
    }
`

