import React from 'react'
import { Link } from 'react-router-dom'
import { CenterHorizontalDiv, Img100pct, Wrapper100pct } from '../styles/Containers'
import { BrushedTxt, ButtonShop, DetailsShop, HillstownTxt } from '../styles/Shop'

const ShopTile = ({data, idCat}) => {
  return (

    <CenterHorizontalDiv style={{height:"auto"}}>
        <Wrapper100pct style={{width:"90%"}}>
            <Link className='black' to={idCat === undefined ? ("./a/" + data.Id)  : ("./" + data.Id)}>
              <Img100pct src={data.Picture}/>
            </Link>
            <Link className='black' to={idCat === undefined ? ("./a/" + data.Id)  : ("./" + data.Id)}>
              <DetailsShop>
                <BrushedTxt>{data.Title}</BrushedTxt>
                <HillstownTxt>{data.Type}</HillstownTxt>
                <BrushedTxt>{data.Price}€</BrushedTxt>
                <ButtonShop className='yellow' to={idCat === undefined ? ("./a/" + data.Id)  : ("./" + data.Id)} style={{marginTop:'10vh', border:'1px solid #FEF102'}}>Shop now</ButtonShop>
              </DetailsShop>
            </Link>
        </Wrapper100pct>
    </CenterHorizontalDiv>

  )
}


export default ShopTile