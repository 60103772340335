import { BaseFrame1078px, BaseFrame100vh, CenterDiv } from '../../../styles/Containers.js';
import bg from "../../../assets/imgs/hp_background_f3.png";
import { SeeMore } from '../../../styles/Homepage.js';
import { Link } from 'react-router-dom';
import Profile from '../../Desktop/artistProfiles/Profile.jsx';
import { getArtists } from '../../Getters.jsx';
import JustMark from '../../Desktop/JustMark.jsx';
import JustTim from '../../Desktop/JustTim';
import MediaQuery from 'react-responsive';
import { minDevice } from '../../../styles/devices.js';
import { useEffect } from 'react';

const Artists = () => {

  const artists = getArtists();
  const artistPanel = artists.map((artist) => <Profile artist={artist}></Profile>);
          
  return (
     <BaseFrame100vh style={{position:"relative"}} img={bg} id="Frame3">
     {/* <BaseFrame1078px img={bg} id="Frame3"> */}
      <JustMark/>
      <JustTim/>
      {/* CenterDiv added bc 3 portraits */}
        <CenterDiv style={{top:"-70px"}}>
          <div>
            {artistPanel}
          </div>
        </CenterDiv>
      <MediaQuery query={minDevice.laptopM}>
        <Link to="/shop"><SeeMore>See more...</SeeMore></Link>
      </MediaQuery>
    {/* </BaseFrame1078px> */}
    </BaseFrame100vh>
  )
}

export default Artists;
