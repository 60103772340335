import { Box } from 'grid-styled'
import { BaseFrame100vh } from '../styles/Containers';
import { MainFlex } from '../styles/Shop';
import bg from "../assets/imgs/bgShopList.png"
import ResearchFields from '../components/Desktop/ResearchFields'
import ShopItems from '../components/Desktop/ShopItems'
import ShopDisclaimer from '../components/Desktop/ShopDisclaimer'
import ShopCategories from '../components/Desktop/ShopCategories'
import { useState } from 'react';
import { useEffect } from 'react';
import ShopItemsByCat from '../components/Desktop/ShopItemsByCat';
import ShopItemsByCatSp from '../components/Desktop/ShopItemsByCatSp';

function ShopListSpecial({idCat}){

  const [Query, setQuery] = useState({})
  // console.log(Query)

  const getResearchFields = (fields) => {
    setQuery(fields);
  }

  return (
    <BaseFrame100vh img={bg}>
      <ShopCategories styled={{padding:"13vh 5vw 1vw 5vw"}}/>
      <MainFlex>

        <Box width={13/13} style={{overflowX:"hidden"}}>
          <ShopItemsByCatSp filters={Query} id={idCat}/>
        </Box>

      </MainFlex>
    </BaseFrame100vh>
  )
}

export default ShopListSpecial