import {FullTim,TimArrowDiv, TimDiv, TimTxt} from '../../styles/Homepage.js';
import Tim from '../../assets/imgs/TimTheCat.png'
import TimArrow from '../../assets/imgs/TimArrow.png'
import TimSound from '../../assets/sound/Tim.mp3'
import { useState } from 'react';

// Tim was originaly the white cat, Pablo
const JustTim = () => {
    const [ TimName, setTimName ] = useState(false);
    const audio = new Audio(TimSound);
    // const [play] = useSound(TimSound);

    return (
      <FullTim className='cat'>
        <TimDiv src={Tim} 
          onMouseEnter={() => {setTimName(true); audio.play()}}
          onMouseLeave={() => {setTimName(false)}}/>
        {TimName && <TimArrowDiv>
          <img src={TimArrow} />
          <TimTxt>I'm Pablo</TimTxt>
          {/* <embed src={TimSound} loop={false} autostart="true" width="0"
           height="0"></embed> */}
        </TimArrowDiv>}
      </FullTim>
    )
  }

export default JustTim;