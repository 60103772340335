import { useEffect } from "react";
import { useState } from "react";
import {NavLink, useParams } from "react-router-dom"
import GetAllArtists from "../backend/artists/GetAllArtists";

const HardCodedCategories = () => {
    const {type} = useParams();
    const [Artist, setArtist] = useState(type === "artist" ? true : false)
    const [Artists, setArtists] = useState([])

    const getArtists = async () => {
      setArtists(await GetAllArtists())
    }

    useEffect(() => {
      getArtists()
    }, [])
    

  if(type === "3dphotos"){
    return (
        <div className="shopCat" style={{textAlign:"center"}}>
            {/* TODO: correct these tags too */}
            {/* <NavLink className="yellow" to="/shop/3dphotos/dfe6702b-5659-4685-9191-0881fc31eb30">Barcelona Places</NavLink><br/><br/>
            <NavLink className="yellow" to="/shop/3dphotos/85120ecb-757f-47a3-afef-dd1f94e29ac3">Graffiti</NavLink><br/><br/>
            <NavLink className="yellow" to="/shop/3dphotos/8fde93f0-afa4-4ccf-8764-9f50b16d87a3">Shops & Restaurants</NavLink> */}
            <NavLink className="yellow" to="/shop/3dphotos/115c25ea-faf9-4b74-abf9-b3db8f6c0d0e">Barcelona Places</NavLink><br/><br/>
            <NavLink className="yellow" to="/shop/3dphotos/8ea99420-860b-41d2-aa46-8f993518a8e6">Graffiti</NavLink><br/><br/>
            <NavLink className="yellow" to="/shop/3dphotos/f330b19f-7727-43a1-9ad5-99f9ce877915">Shops & Restaurants</NavLink>
        </div>
    )
  }

  if(type === "paintings" || type === "artist"){
    return (
      <>
        <div className="shopCat" style={{textAlign:"center"}}>
          {!Artist && <p style={{color: "#c54def", fontSize:"2.5vh"}} className="purple" onClick={() => setArtist(true)}>Looking for an artist ?</p>}
          {Artist && <p style={{color: "#c54def", fontSize:"2.5vh"}} className="purple" onClick={() => setArtist(false)}>Looking for a serie ?</p>}
        </div>

        {Artist &&
          <div className="shopCat" style={{textAlign:"center"}}>
            <div>
              {Artists.map((artist) => <>
                  <NavLink className="yellow" to={"/shop/artist/" + artist.id}>
                    {artist.name}
                  </NavLink>
                  <br/><br/>
                </>)}
            </div>
          </div>
        }
          <div className="shopCat" style={{textAlign:"center"}}>            
            {!Artist &&
              <div>
                {/* TODO: Make them not hardcoded and from the API */}
                {/* <NavLink className="yellow" to="/shop/paintings/bd2c294d-f399-407f-8fe3-67b13070d35d">New Yorker</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/c0995af1-e1fc-473a-8d9c-02e46daba129">Hearts</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/dd800e5d-66e0-4918-8bcd-f42288b9607f">Piel de Luna</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/07628faa-1193-407a-acde-0b82718b85a5">Desires</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/db246521-5e38-4152-97d5-4ce79f68ab54">Abstract</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/b9f58231-1c5c-4907-be36-93534d386891">Hall of Fame</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/a816cc98-6503-4324-83b3-812acc673095">Urban Art</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/e437758e-b59e-4a95-b496-10b366b5f82c">Minimalist</NavLink><br/><br/>
              */}
                <NavLink className="yellow" to="/shop/paintings/fb612412-eff5-406c-8e45-603c9e02c60f">New Yorker</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/17cd1622-f6c4-4d43-89c5-d71a64ef0abb">Hearts</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/f421c651-be68-4c85-9595-8754fa288b86">Piel de Luna</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/a993ecac-8edb-423f-8605-71b49e077cd6">Desires</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/f9624123-a76f-40c8-9e15-59fd66a4bb70">Abstract</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/4a8184df-9bad-450d-9dcf-4327bb37d64c">Hall of Fame</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/d37092ad-a4b4-4631-85a3-7095e3a3fe19">Urban Art</NavLink><br/><br/>
                <NavLink className="yellow" to="/shop/paintings/9ed84736-7b82-459e-8507-a4c5ef7c1527">Minimalist</NavLink><br/><br/>
             
              </div>
          }
        </div>
      </>
    )
  }
}

export default HardCodedCategories