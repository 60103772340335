import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/Global.css';
import App from './App';
import axios from 'axios';

const root = ReactDOM.createRoot(document.getElementById('root'));
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER_MAXO

root.render(
  <React.StrictMode>
    <div className="mainDiv container-fluid">
      <App/>
    </div>
  </React.StrictMode>
);
