import React from 'react'
import bg from '../../../assets/imgs/bgAboutMob2.png'

import P1 from '../../../assets/imgs/Galerias/P1.png';
import Petritxol from '../../../assets/imgs/Galerias/Petritxol.png';
import PortalNou from '../../../assets/imgs/Galerias/PortalNou.png';
import Carders from '../../../assets/imgs/Galerias/Carders.png';
import Olles from '../../../assets/imgs/Galerias/Olles.png';
import Princesa from '../../../assets/imgs/Galerias/Princesa.png';
import { CenterHorizontalDiv } from '../../../styles/Containers';
import ListGalleries from '../ListGalleries';


const PlacesM = () => {
    const frames = 
    [
      {
        "Name": "Galeria Maxó Petrixol",
        "Adress":"Carrer de Petritxol, 18",
        "Adress2":"08002, Barcelona, Spain",
        "Picture":Petritxol,
        "PosX": 10,
        "PosY": 34,
        "PosZ": 10,
        "Tilt": -12,
        "MapsColor":["black", "#ffeb07"],
        "Link":"https://goo.gl/maps/FjA5rY1nrHkX7co56"
      },
      {
        "Name": "Galeria Maxó Pi 1",
        "Adress":"Career Del Pi, 1",
        "Adress2":"08002, Barcelona, Spain",
        "Picture":P1,
        "PosX": 23,
        "PosY": 15,
        "PosZ": 9,
        "Tilt": -12,
        "MapsColor":["black", "#c54def"],
        "Link":"https://goo.gl/maps/JYagAHrNCbBKiUMy9"
      },
      {
        "Name": "Galería Maxó Portal Nou",
        "Adress":"Carrer del Portal Nou, 29",
        "Adress2":"08003, Barcelona, Spain",
        "Picture":PortalNou,
        "PosX": 46,
        "PosY": 51,
        "PosZ": 0,
        "Tilt": 13,
        "MapsColor":["black", "#ffeb07"],
        "Link":"https://goo.gl/maps/Ew4K6xJKA9UokwC87"
      },
      {
        "Name": "Galería Maxó Carders",
        "Adress":"Carrer dels Carders, 29",
        "Adress2":"08003, Barcelona, Spain",
        "Picture":Carders,
        "PosX": 25,
        "PosY": 67,
        "PosZ": 0,
        "Tilt": -3,
        "MapsColor":["white", "black"],
        "Link":"https://goo.gl/maps/Uss5C5w74QbdGPBa6"
      },
      {
        "Name": "Galería Maxó Olles",
        "Adress":"Plaça de les Olles, 1",
        "Adress2":"08003, Barcelona, Spain",
        "Picture":Olles,
        "PosX": 8,
        "PosY": 49,
        "PosZ": 0,
        "Tilt": -20,
        "MapsColor":["black", "#c54def"],
        "Link":"https://goo.gl/maps/8Up9acuhifuQnsEr8"
      },
      {
        "Name": "Galería Maxó Princesa",
        "Adress":"Carrer del Princesa, 15",
        "Adress2":"08003, Barcelona, Spain",
        "Picture":Princesa,
        "PosX": 43,
        "PosY": 30,
        "PosZ": 0,
        "Tilt": -20,
        "MapsColor":["white", "black"],
        "Link":"https://goo.gl/maps/1hiaQYRWnV6yoLjX7"
      }
    ]

  return (
    <CenterHorizontalDiv style={{backgroundImage:"url("+bg+")", position:"relative", backgroundRepeat:"no-repeat", backgroundSize:"cover", paddingBottom: "100px"}}>   
        <div>
            {frames.map((frame) =>
                <ListGalleries data={frame}/>
            )}
        </div>
    </CenterHorizontalDiv>
  )
}

export default PlacesM