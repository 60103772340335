import { NavLink } from "react-router-dom";
import MediaQuery from "react-responsive"
import { useLocation } from "react-router-dom";

const ShopCategories = ({styled}) => {
  const route = useLocation().pathname.substring(1).split('/');
  // console.log(route);

  return (
    <div style={styled}>
        <NavLink className="ShopLink yellow"></NavLink>
        <NavLink to="/shop/3dphotos/115c25ea-faf9-4b74-abf9-b3db8f6c0d0e" className={"ShopLink yellow" + (route[1] == "3dphotos" ? " active" : null)}>3D Photos</NavLink>&nbsp;
        <NavLink to="/shop/paintings/fb612412-eff5-406c-8e45-603c9e02c60f" className={"ShopLink yellow" + (route[1] == "artist" || route[1] == "paintings" ? " active" : null)}>Paintings</NavLink>&nbsp;
        {/* <MediaQuery orientation="portrait" maxHeight={792}>
          <br></br>
        </MediaQuery> */}
        <NavLink to="/shop/collection" className="ShopLink">Collection</NavLink>&nbsp;
        {/* <NavLink to="/shop/giftcards" className="ShopLink">Gift Cards</NavLink> */}
        {/* <NavLink to="/shop/artists" className="ShopLink yellow">Artists</NavLink> */}

    </div>
  )
}

export default ShopCategories