import { Box } from 'grid-styled'
import React from 'react'
import { ContactTitle, ContactTxtSm, ContainerTxt, FlexShipping } from '../../styles/Contact'

const TxtShipping = () => {
  return (
    <>
          <ContainerTxt>
            <ContactTitle>Shipping</ContactTitle>
            <ContactTxtSm>We are shipping throught Correos, the Spanish National Postal Service. Average time for the shippings are:
              <FlexShipping>
                <Box width={1/3}>
                Spain<br/>4-5 days
                </Box>
                <Box width={1/3}>
                Europe<br/>7-10 days
                </Box>
                <Box width={1/3}>
                Global<br/>12-15 days
                </Box>
              </FlexShipping>

            </ContactTxtSm>
          </ContainerTxt>
          <ContainerTxt>
            <ContactTitle>Shipping cost for Cuadritos</ContactTitle>
            <ContactTxtSm>
              <FlexShipping>
                <Box width={1/4}>
                  &nbsp;
                  <br/>
                  Upto 3:
                  <br/>
                  4 or more:
                </Box>
                <Box width={1/4}>
                  Spain
                  <br/>
                  € 9.20
                  <br/>
                  € 9.65
                </Box>
                <Box width={1/4}>
                  Europe
                  <br/>
                  € 18.20
                  <br/>
                  € 26.10
                </Box>
                <Box width={1/4}>
                  Global
                  <br/>
                  € 26.25
                  <br/>
                  € 30.25
                </Box>
              </FlexShipping>


Note: For bulk orders, the price of shipping would change.
            </ContactTxtSm>
          </ContainerTxt>

          <ContainerTxt>
            <ContactTitle>Shipping cost for Paintings</ContactTitle>
            <ContactTxtSm>
              <FlexShipping>
                <Box width={1/4}>
                  &nbsp;
                  <br/>
                  Upto 2:
                  <br/>
                  Upto 4:
                </Box>
                <Box width={1/4}>
                  Spain
                  <br/>
                  € 9.65
                  <br/>
                  € 10.85
                </Box>
                <Box width={1/4}>
                  Europe
                  <br/>
                  € 20
                  <br/>
                  € 36
                </Box>
                <Box width={1/4}>
                  Global
                  <br/>
                  € 26.95
                  <br/>
                  € 40
                </Box>
              </FlexShipping>
              Free Shipping for more than 4 paintings.

            </ContactTxtSm>
          </ContainerTxt>
        </>
  )
}

export default TxtShipping