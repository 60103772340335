import { Link } from 'react-router-dom'
import ArrowFull from "../../assets/imgs/ArrowFull.png"
import { Arrow, ArrowImg } from '../../styles/Form'

const ContinueArrow = ({linking}) => {
  return (
    <Arrow>
        <Link className="white" to={linking}>
          <ArrowImg src={ArrowFull}/>
        </Link>
    </Arrow>
  )
}

export default ContinueArrow