import axios from "axios";

const createCustomer = async (user) => {
    try { 
        const response = await axios.post('customers/new', {
          "name" : user.name,
          "email" : user.email,
          "contact": user.phone,
          "address": user.address,
          "city": user.city,
          "country": user.country,
          "postal": user.zip
        });
        if (response.status === 201) {
          // console.log(response)
          return response.data.Customer.id
        }
      } catch (error) {
        console.log(error)
      }
}

export default createCustomer;