import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CartProvider from './components/CartContext';
import { minDevice, maxDevice } from './styles/devices';

import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Shop from './pages/Shop';
import ShopList from './pages/ShopList';
import ShopArticle from './pages/ShopArticle';
import ShippingForm from './pages/ShippingForm';
import Overlay from './components/Desktop/Overlay';
// import Loader from './components/Desktop/Loader';
import E404 from './pages/E404';
import { AcceptedPurchase } from './pages/AcceptedPurchase';
import Checkout from './pages/Checkout';
import Payment from './pages/Payment';
import MediaQuery from 'react-responsive';

import OverlayM from './components/Mobile/OverlayM';
import ShopListM from './pages/Mobile/ShopListM';
import ShopArticleM from './pages/Mobile/ShopArticleM';
import CheckoutM from './pages/Mobile/CheckoutM';
import PaymentM from './pages/Mobile/PaymentM'
import ShopListSpecial from './pages/ShopListSpecial';
import ShopListMSp from './pages/Mobile/ShopListMSp';
import ShopListById from './pages/ShopListById';
import ShopListMById from './pages/Mobile/ShopListMById';
import Artists from './components/Mobile/homepage/ArtistsM';
import ArtistsM from './components/Desktop/homepage/Artists';

function App(){

    return(
        <CartProvider>
            <BrowserRouter>
                {/* <Loader/> */}

                <MediaQuery query={minDevice.miniLaptop}>
                    <Overlay/>
                    <Routes>
                        <Route path="/shop/:type" element={<ShopList />}/>
                        {/* <Route path="/shop/:type/:id" element={<ShopArticle/>}/> */}
                        <Route path="/shop/:type/:idCat/:id" element={<ShopArticle/>}/>
                        <Route path="/shop/:type/:idCat" element={<ShopListById/>}/>
                        <Route path="/shop/special-payments" element={<ShopListSpecial idCat="210aa8d8-c887-4624-8ed7-a8fd5d9f1270"/>}/>
                        <Route path="/shop/collection" element={<ShopListSpecial idCat="46b80d45-ffc5-494d-b50d-0e28e5256c62"/>}/>
                        <Route path="/shop/giftcards" element={<ShopListSpecial idCat="a30d75f2-82aa-4cbb-87fc-25b02fc75e48"/>}/>

                        <Route path="/cart/checkout" element={<Checkout />}/>
                        <Route path="/cart/payment" element={<Payment />}/>
                        <Route path="*" element={<E404 />}/>

                        <Route path="/" element={<Home />}/>
                        <Route path="/about" element={<About />}/>
                        
                        <Route path="/shop" element={<Shop />}/>
                        
                        <Route path="/contact" element={<Contact />}/>
                        <Route path="/shipping" element={<Contact />}/>
                        
                        <Route path="/cart/shipping" element={<ShippingForm />}/>
                        <Route path="/cart/accepted" element={<AcceptedPurchase />}/>

                        <Route exact path="/artists" element={<>
                            <MediaQuery query={minDevice.laptopM}>
                                <ArtistsM />
                            </MediaQuery>
                            <MediaQuery query={maxDevice.laptopM}>
                                <Artists />
                            </MediaQuery>
                        </>}/>

                    </Routes>
                </MediaQuery>

                <MediaQuery query={maxDevice.miniLaptop}>
                    <OverlayM/>

                    <Routes>
                        <Route path="/shop/:type" element={<ShopListM />}/>
                        <Route path="/shop/:type/:idCat/:id" element={<>
                                <MediaQuery orientation='portrait'>
                                    <ShopArticleM/>
                                </MediaQuery>
                                <MediaQuery orientation='landscape'>
                                    <ShopArticle/>
                                </MediaQuery>
                        </>}/>

                        <Route path="/shop/:type/:idCat" element={<>
                                <MediaQuery orientation='portrait'>
                                    <ShopListMById/>
                                </MediaQuery>
                                <MediaQuery orientation='landscape'>
                                    <ShopListById/>
                                </MediaQuery>
                        </>}/>

                        <Route path="/cart/checkout" element={<CheckoutM />}/>
                        <Route path="/cart/payment" element={<PaymentM />}/>
                        <Route path="*" element={<E404 />}/>
                        <Route path="/shop/special-payments" element={<ShopListMSp idCat="210aa8d8-c887-4624-8ed7-a8fd5d9f1270"/>}/>
                        <Route path="/shop/collection" element={<ShopListMSp idCat="46b80d45-ffc5-494d-b50d-0e28e5256c62"/>}/>
                        <Route path="/shop/giftcards" element={<ShopListMSp idCat="a30d75f2-82aa-4cbb-87fc-25b02fc75e48"/>}/>


                        <Route exact path="/" element={<Home />}/>
                        <Route path="/about" element={<About />}/>
                        <Route exact path="/artists" element={<Artists />}/>
                        
                        <Route path="/shop" element={<Shop />}/>
                        
                        <Route path="/contact" element={<Contact />}/>
                        <Route path="/shipping" element={<Contact />}/>
                        
                        <Route path="/cart/shipping" element={<ShippingForm />}/>
                        <Route path="/cart/accepted" element={<AcceptedPurchase />}/>
                        
                    </Routes>
                </MediaQuery>

                {/* <Routes>

                    <Route path="/" element={<Home />}/>
                    <Route path="/about" element={<About />}/>
                    
                    <Route path="/shop" element={<Shop />}/>
                    
                    <Route path="/contact" element={<Contact />}/>
                    <Route path="/shipping" element={<Contact />}/>
                    
                    <Route path="/cart/shipping" element={<ShippingForm />}/>
                    <Route path="/cart/accepted" element={<AcceptedPurchase />}/>

                    
                </Routes> */}
            </BrowserRouter>
            
        </CartProvider>
    )
}

export default App;