import Cookie from "js-cookie"

const SetCookie = (CookieName, Value, Expires) => {
  Cookie.set(CookieName, Value, {
    expires:Expires,
    secure:true,
    sameSite:'none', // TODO: change to 'strict' when in proper production
    path:'/'
  })
}

export default SetCookie