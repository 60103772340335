import { Box, Flex } from "grid-styled";
import { Price, SubTitle, Title } from "../../styles/Cart";
// import CheckBox from "../Desktop/CheckBox";
import Bin from '../../assets/imgs/Bin.png';
import { useContext } from "react";
import { CartContext } from "../CartContext";
import { Link } from "react-router-dom";
import { CenterDiv } from "../../styles/Containers";
import { countType } from "../Getters";
import GetCookie from "../../hooks/GetCookie";

const ItemList = ({dataset}) => {
    const cart = useContext(CartContext);
    let paintings = 0
    return(
      dataset.map(
        (data) => (<div style={{position: "relative"}}>
            <span style={{display:"none"}}>
            {data.Art === "paintings" && countType('paintings', {dataset}) >= 2
            ? paintings += 1 
            :""
            }
            </span>
            <Flex style={{padding:"10px 0 0px 0"}}>
              <Box width={1/4}>
                <img width="100%" src={data.Picture}></img>
              </Box>

              <Box style={{paddingLeft:"15px"}} width={2/4}>
                <Title>
                  {data.Title}
                </Title>
                <SubTitle>
                  {data.SubTitle}
                </SubTitle>
                <Price>
                  {
                    (paintings == 2 && GetCookie("offer25"))
                    ? <>
                    {data.Price * 0.75}€&nbsp;
                    <span style={{textDecoration:"line-through", color:"#c54def"}}>
                    {data.Price}€
                    </span>
                    </>
                    : (data.Price * cart.getQuantity(data.Id))+"€"
                  }

                  <div style={{fontSize:"50%"}}>
                    {/* {data.Price + "€/u"} */}
                  </div>
                </Price>
              </Box>

              <Box width={1/4}>
                <CenterDiv>
                  {data.Art !== "paintings"
                  ? <Link onClick={() => cart.rmOneFromCart(data.Id)}>-&nbsp;</Link>
                  : ""
                  }

                  {data.Art !== "paintings"
                  ? cart.getQuantity(data.Id)
                  : <Link onClick={() => cart.rmOneFromCart(data.Id)}>
                      <img style={{height:"25px"}} src={Bin}/>
                    </Link>
                  }
                  
                  {data.Art !== "paintings"
                  ? <Link onClick={() => cart.addCart(data.Id)}>&nbsp;+</Link>
                  : ""
                  } 
                </CenterDiv>
              </Box>
          </Flex>
            {/* {data.Option != "" ? 
            <div style={{transform : "scale(0.6)"}}>
              <CheckBox txt={data.Option} id={data.Id} checked={data.Frame}/> 
            </div>
            : <div>&nbsp;</div>} */}
        </div>)
      )
    )
  }

  export default ItemList;