import { Box } from 'grid-styled'
import { BaseFrame100vh } from '../styles/Containers';
import { MainFlex } from '../styles/Shop';
import bg from "../assets/imgs/bgShopList.png"
import ResearchFields from '../components/Desktop/ResearchFields'
import ShopItems from '../components/Desktop/ShopItems'
import ShopDisclaimer from '../components/Desktop/ShopDisclaimer'
import ShopCategories from '../components/Desktop/ShopCategories'
import { useState } from 'react';
import { useEffect } from 'react';
import HardCodedCategories from '../components/Desktop/HardCodedCategories';

function ShopList() {

  const [Query, setQuery] = useState({})
  // console.log(Query)

  const getResearchFields = (fields) => {
    //setQuery(fields);
  }

  return (
    <BaseFrame100vh img={bg}>
      <ShopCategories styled={{padding:"13vh 5vw 1vw 5vw"}}/>
      <MainFlex>
        <Box width={2/13} style={{padding : "0vh 10px"}}>
          {/* <ResearchFields getResearchFields={getResearchFields}/> */}
          <HardCodedCategories/>
        </Box>

        <Box width={9/13} style={{overflowX:"hidden"}}>
          <ShopItems filters={Query}/>
        </Box>

        <Box width={2/13}>
          <ShopDisclaimer/>
        </Box>
      </MainFlex>
    </BaseFrame100vh>
  )
}

export default ShopList