import { Box, Flex } from 'grid-styled'
import { useContext, useEffect, useState } from 'react'
import { PaymentFrame, SubTitle, Title, Price, Infos, Bar } from '../styles/Cart'
import { BaseFrame100vh, CenterDiv } from '../styles/Containers'
import { DivCart } from '../styles/Form'
import Breadcrumbs from '../components/Desktop/Breadcrumbs'
import { getCart, getShippingOpt, getPrice, getUser, getCartAsync, getArticle } from '../components/Getters'
import ContinueArrow from '../components/Desktop/ContinueArrow'
import bg from "../assets/imgs/bgPayment.png";
import MiniInfoCart from '../components/Desktop/MiniInfoCart'
import { CartContext } from '../components/CartContext'
import StripeCheckout from 'react-stripe-checkout'
import axios from 'axios'
import { Navigate, redirect } from 'react-router-dom'
import createCustomer from '../components/backend/createCustomer'
import createOrder from '../components/backend/createOrder'
import GetCookie from '../hooks/GetCookie'
import addItemsToOrder from '../components/backend/addItemsToOrder'
import SetCookie from '../hooks/SetCookie'
import RemoveCookie from '../hooks/RemoveCookie'
import sendEmailConfimation from '../components/backend/sendEmailConfimation'

const Payment = () => {

  const cart = useContext(CartContext);

  // const dataset = getCart(cart.items);

  const [dataset, setdataset] = useState([])
  const [Validate, setValidate] = useState(false)
  const [SpecialPay, setSpecialPay] = useState(false)
 

  const AllArt = async () => {
    setdataset(await getCartAsync(cart.items))
  }

  useEffect(() => {
      AllArt()
  }, [])

  const shippingOpt = getShippingOpt({dataset});
  const user = getUser();
  // console.log(user)

  const [ShippingFee, setShippingFee] = useState(0)
  const [TotalPrice, setTotalPrice] = useState(getPrice({dataset}, ShippingFee)[1])
  const [SelectedShipping, setSelectedShipping] = useState(false)

    const updatePrice = (event) => {
      setShippingFee(event.target.value);
      setSelectedShipping(true)
      // setTotalPrice(getPrice({dataset}, ShippingFee)[1])
      // console.log(ShippingFee)
    }

    useEffect(() => {
      setTotalPrice(getPrice({dataset}, ShippingFee)[1])
      dataset.forEach(element => {
        if(element.seriesId === "210aa8d8-c887-4624-8ed7-a8fd5d9f1270"){
          setSpecialPay(true);
        }
      });
    }, [ShippingFee])

    const payNow = async token => {

      try {
        const link = SpecialPay ? 'https://mm-backoffice.herokuapp.com/api/7afb8645-2164-4584-9546-3d0730fecb6a/orders/specialPayment' : 'https://mm-backoffice.herokuapp.com/api/7afb8645-2164-4584-9546-3d0730fecb6a/orders/payment/maxo'
        const response = await axios.post(link, {
          amount: Math.floor(TotalPrice * 100),
          token,
        });
        
        if (response.status === 200) {
          console.log(response)
            const token = response.data.checkId;
            try{
              const customerID = await createCustomer(user);
              const orderID = await createOrder(getPrice({dataset}, 0)[1], parseFloat(ShippingFee), getPrice({dataset}, ShippingFee)[1], user.comment, customerID, token);
              
              // console.log(orderID);

              let i = 0;
              let paintings = 0;
              dataset.forEach(element => {
                if(dataset[i].Art === "paintings"){
                (paintings === 2 && GetCookie("offer25"))
                ? dataset[i].totalPrice = dataset[i].Price * 0.75
                : dataset[i].totalPrice = dataset[i].Price * dataset[i].Quantity;
                i++;
                // console.log(dataset[i])
                }
              });
              
              await addItemsToOrder(orderID[0], dataset);

              RemoveCookie("offer25");
              RemoveCookie("offerShip");
              SetCookie("orderID", orderID[1], 1);

              await sendEmailConfimation(orderID[1], dataset, user, ShippingFee, getPrice({dataset}, 0)[1], getPrice({dataset}, ShippingFee)[1]);
              
              setValidate(true);
            }catch(e){
              console.log(e)
            }
        }
        // console.log(response)
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <BaseFrame100vh img={bg}>
      <CenterDiv>
        <div>
          <Breadcrumbs number={["3", "Payment details"]}/>
          <DivCart>
            {/* {SelectedShipping && <a onClick={checkout}><ContinueArrow/></a>} */}
            <Flex>
              <Box style={{maxHeight:"25vh", overflowX:"hidden"}} width={1/2} id="CartMini">
                <MiniInfoCart dataset={dataset}/>
              </Box>

              <Box width={1/2} id="ShippingOpt">
                  <Title style={{textAlign:'right', color:'white', fontSize:"90%", marginTop:"50px", marginBottom:"0",}}>DELIVERY OPTIONS:</Title>
                  {Object.entries(shippingOpt).map(
                    ([key, value]) => 
                    <div className='yellow' style={{padding:"20px 0", marginBottom:"1.5vh", width:"auto"}}>
                      <label className='yellow styledCheckboxTxt' style={{position:"absolute", right:"150px", transform:"scale(0.9)"}} for={value}>{key + " : " + value + "€"}</label>
                      <div className='yellow styledCheckbox ' style={{position:"absolute", right:"100px",transform:"scale(0.9)"}}>
                          <input className='yellow' type="radio" id={value} name="shipping" value={value} onChange={updatePrice}/>
                          <label className='yellow' for={value}></label>         
                      </div>
                  </div>
                    // <CheckBoxShipping style={{position:"absolute", right:"0px"}} txt={key + " : " + value + "€"} id={value}></CheckBoxShipping>
                  )}
              </Box>
            </Flex>
          
            <Bar style={{marginBottom:"2vh"}}/>

            <Flex>
              <Box width={0.4} id="CartMini">
                {Object.entries(user).map(
                  ([key,value]) =>
                  <SubTitle>{key} : {value}</SubTitle>
                )}
              </Box>

              <Box style={{}} width={0.6} id="ShippingOpt">
                <Price style={{textAlign:"right"}}>
                Total : { getPrice({dataset}, ShippingFee)[1] }€
                </Price>

                <br/>

                {SelectedShipping && <div style={{textAlign:"right"}}>
            
                  <StripeCheckout className="blue" id
                    stripeKey={SpecialPay ? "pk_live_51HLo2uJKvp6KGHI6jWo3mlamwb3lNG55CUkwRyAFubNAVBLuaxIlCzcNDvnj1qTtHsKYgKWdTuvixfQdchf5ZvNA00z9tHONGM" : "pk_live_51HLo2uJKvp6KGHI6jWo3mlamwb3lNG55CUkwRyAFubNAVBLuaxIlCzcNDvnj1qTtHsKYgKWdTuvixfQdchf5ZvNA00z9tHONGM"}
                    label="Pay Now"
                    name="Pay With Credit Card"
                    //billingAddress
                    //shippingAddress
                    currency="EUR"
                    bitcoin
                    amount={TotalPrice*100}
                    description={`Purchase on Galeria Maxo for ${TotalPrice}€`}
                    token={payNow}
                    style={{scale:"1.3", marginRight:"20px"}}
                  />
                  <br/>
                  </div>
                }
              </Box>
            </Flex>
            

          </DivCart>
          {Validate && <Navigate to="/cart/accepted" replace/>}
        </div>
      </CenterDiv>
    </BaseFrame100vh>
  )
}

export const CheckBoxShipping = (props) => {
  const name = props.id

  const [ShippingFee, setShippingFee] = useState(0)

  const updatePrice = (event) => {
    setShippingFee(event.target.value);
    // console.log(ShippingFee)
  }
  
  return (
  <div style={{padding:"20px 0", marginBottom:"20px", width:"auto"}}>
      <label style={{position:"absolute", right:"150px", transform:"scale(0.9)"}} for={name} className='styledCheckboxTxt'>{props.txt}</label>
      <div style={{position:"absolute", right:"100px",transform:"scale(0.9)"}} className="styledCheckbox">
          <input type="radio" id={name} name="shipping" value={name} onChange={updatePrice}/>
          <label for={name}></label>          
      </div>
  </div>
)
}

export default Payment