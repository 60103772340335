import styled from "styled-components";
import {BaseFrame100vh, BaseFrame1078px} from '../styles/Containers.js'
import { Box, Flex } from 'grid-styled';
import PolFrameImg from '../assets/imgs/Polaroid_Frame2.png';
import { maxDevice } from "./devices.js";
import border from '../assets/imgs/pattern_polaroid.png'

// Description
export const BottomLeftImg = styled.img`
    position:absolute;
    bottom:0;
    left:0;
`

export const FrameGif = styled(BottomLeftImg)`
    float: left;
    max-width: 370px;
    width:20vw;
    left:-10px;
    bottom:-50px;
    @media ${maxDevice.miniLaptop} {
        width:calc(5vw + 90px);
    }
`

export const FlexMain = styled(Flex)`
    width:93vw;
    font-size:2vh;
    padding-top:16vh;
`

export const DivTopIntoBox = styled.div`
    background-color:rgba(0, 0, 0, 0.6);
    padding: 10px 20px;
    margin: 0 20px;

    @media ${maxDevice.laptopM} {
        font-size: 0.8em;
    }
    @media ${maxDevice.miniLaptop} {
        font-size: 0.7em;
        padding: 50px 40px 100px 40px;
        position:relative; 
        margin:-5px 0 0 50px;
        text-align: justify;
    }
`

export const DivBotIntoBox = styled.div`
    background-color:rgba(0, 0, 0, 0.6);
    padding: 13px 20px;
    margin: 0 20px 0 30vh;
`

export const MaxoImage = styled.img`
    max-width:20vw;
    border: solid transparent;
    border-color: white; 
    /* border-bot-color: linear-gradient(to left, #333, #333 50%, #eee 75%, #333 75%);; */
    /* border-image: url(${border}) 15 round; */
    /* border-image-width: 37px; */
    border-image-repeat: repeat;
    border-width: 20px 20px 80px 20px;
    box-shadow: 8px 8px 12px #00050c;
    transition: ease-in-out 0.3s;

    &:hover {
    rotate: 5deg;
    max-width: 22vw;
    transform: translate(-2vw, -2vh);
    }
`

//Places
export const PolDiv = styled.div`
        transition: all 0.3s ease-out;
        position: absolute;
        height: 45vh;

               
        &:hover{
        transition: all 0.3s ease-out;
        margin: 3vw;
        transform: scale(1.2) translate(-15%, -15%);
        z-index:1000 !important;
        rotate: 4deg;
        color : black ;
        
        }
`

export const PolFrame = styled.div`
    content: url(${PolFrameImg});
    transition: all 0.3s ease-out;
    z-index: 10;
    height: 45vh;

    background-repeat: no-repeat;
    background-position: top 17% center;
    background-size: 90%;
`
export const PolTxt = styled.div`
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100%;
    height:21%;
    z-index: 20;
    color: black;
    font-size: 5vh;
    rotate:1deg;
`

export const PolName = styled.p`
    font-size:43%;
    letter-spacing:2px;
    margin: 0;
    padding-bottom:5px
`

export const PolAdress = styled.p`
    font-size:25%;
    letter-spacing:3px;
    padding-bottom:5px;
    margin: 0;
`

export const PolMaps = styled.p`
    font-size:20%;
    margin: 0 auto;
    padding: 2px 0;
    letter-spacing:2px;
    text-align:center;
    width:42%;
`

export const ContainGallery = styled.div`
    display: flex;
    height: 11.5vh;
    width: 100%;
    margin: 6% 0;

    &:nth-child(odd){
        flex-direction: row-reverse;
        border: solid black 1vh;
        border-left: none;
    }

    &:nth-child(even){
        flex-direction: row;
        border: solid black 1vh;
        border-right: none;
    }
`

export const FlexImg = styled.div`
    flex: 1;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
`

export const FlexTxt = styled.div`
    flex: 2;
    background-color: white;
`

export const TtlMini = styled.p`
    color:black;
    font-size: 2.5vh;
    text-align:center;
    line-height:2vh;
    padding:0;
    margin: 0.8vh 0.4vh;

    //@media orientation
`

export const STtlMini = styled(TtlMini)`
    font-size: 1.5vh;
    margin: 0.8vh 0.4vh;

`