import axios from "axios";
import randStr from "./randStr";

const createOrder = async (price, shipping, total, notes, customerId, stripeId) => {
    let idOrder = randStr(5);

    console.log("SHIVA-MAXO-ORDER")
    console.log({total})
    
    try {
        const response = await axios.post('orders/new', {
            idOrder,
            price,
            shipping,
            total,
            status:false,
            notes,
            customerId,
            stripeId
        });
        
        if (response.status === 201) {
            // console.log(response)
            return [response.data.order.id, response.data.order.idOrder]
        }

    }catch(e){
        console.log(e);
    }
    
}

export default createOrder