import {GiftDiv, GiftCard, Dali} from '../styles/Shop.js';
import {PolDiv, PolFrame, PolTxt} from '../styles/Homepage.js';
import {BaseFrame100vh, CenterDiv} from '../styles/Containers';
import giftcard from '../assets/imgs/giftcard.png';
import dali from '../assets/imgs/dali.png';
import { Link } from 'react-router-dom';
import bg from "../assets/imgs/shop_bg1.png"
import PolaroidMinishop from '../components/Desktop/PolaroidMinishop';
import MediaQuery from 'react-responsive';
import { minDevice, maxDevice } from '../styles/devices.js';
import PolaroidMiniShopM from '../components/Mobile/PolaroidMiniShopM.jsx';

const Shop = () => {

  return (
 
    <BaseFrame100vh img={bg} id="Frame2">
      <Dali src={dali}/>
      <CenterDiv style={{height:"100%" /*"92%"*/}}>
        {/* <GiftDiv className='white'>
          <Link className='white' style={{color:"black"}} to="/shop/giftcards">
            <GiftCard src={giftcard}/>
            <span><MediaQuery query={minDevice.tablet}>Discover our </MediaQuery>gift cards!</span>
          </Link>
        </GiftDiv> */}

        <MediaQuery query={minDevice.laptopM}>
            <PolaroidMinishop/>
          </MediaQuery>
        
        
        <MediaQuery query={maxDevice.laptopM}>
          <MediaQuery orientation='portrait'>
            <div>
            <div style={{height:"75vh"}}>
              <PolaroidMiniShopM/>
            </div>
            <div style={{height:"20vh"}}>&nbsp;</div>
            </div>
          </MediaQuery>
          <MediaQuery orientation='landscape'>
            <PolaroidMinishop/>
          </MediaQuery>
        </MediaQuery>
      </CenterDiv>
    </BaseFrame100vh>
  )
  
}

export default Shop;
