import React from 'react'
import { ContactTitle, ContactTxtSm } from '../../styles/Contact'
import { ContactTxt } from '../../styles/Contact'

const PageText = ({title, text, link, sm, can}) => {
    if(sm == true){
        return(
            <div style={{paddingBottom:"2vh"}}>
                <ContactTitle >{title}</ContactTitle>
                <a target="_blank" href={link}><ContactTxtSm>{text}</ContactTxtSm></a>
            </div>  
        )
    }else{
        return (
            <div style={{paddingBottom:"2vh"}}>
                <ContactTitle>{title}</ContactTitle>
                <a target="_blank" href={link}><ContactTxt className={can}>{text}</ContactTxt></a>
            </div>
        )
    }
}

export default PageText
