import { BaseFrame100vh, CenterDiv } from '../../styles/Containers'
import { DivCartNoPad, DivCartNoOverflow } from '../../styles/Form';
import Breadcrumbs from '../../components/Desktop/Breadcrumbs';
import ContinueArrow from '../../components/Desktop/ContinueArrow';
import { Bar, Marketting, Price } from '../../styles/Cart';
import {getCart, getPrice, countType, getSentence, getCartAsync} from '../../components/Getters';
import bg from "../../assets/imgs/bgCheckout.png";
import ItemList from '../../components/Mobile/ItemListCheckout';
import { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../components/CartContext';



const CheckoutM = () => {


    const cart = useContext(CartContext);
    // const dataset = getCart(cart.items);
    const [dataset, setdataset] = useState([])

    const AllArt = async () => {
      setdataset(await getCartAsync(cart.items))
    }

    useEffect(() => {
        AllArt()
    }, [cart.items])


    let nbPainting = countType("Painting", {dataset});

    let sentence = getSentence(nbPainting);

  return (
    <BaseFrame100vh img={bg}>
      <CenterDiv style={{}}>
        <div>
          <Breadcrumbs number={["1", "Your Cart"]}/>
            <DivCartNoPad>
              {/* <form method='GET' action="/cart/shipping"> */}
                <DivCartNoOverflow>
                  <div>
                    <ItemList dataset={dataset}/>
                    <Bar/>
                    <Price style={{textAlign:"right"}}>
                    <Marketting style={{position:"relative"}}>
                      {getSentence(countType('paintings', {dataset}))}
                    </Marketting>
                      Total : { getPrice({dataset}, 0)[1] }€
                    </Price>
                  </div>
                  {
                    dataset.length != 0 
                    ? <div style={{width:"100%"}}>
                      <ContinueArrow linking="/cart/shipping"/>
                     </div>
                      : ""
                  }
                </DivCartNoOverflow>
              {/* </form> */}
            </DivCartNoPad>
        </div>
      </CenterDiv>
    </BaseFrame100vh>
  )
}


export default CheckoutM;