import { TtlMini, ContainGallery, FlexImg, FlexTxt, STtlMini } from '../../styles/About'
import { CenterDiv } from '../../styles/Containers'

const ListGalleries = ({data}) => {
  return (
    <a target="_blank" href={data.Link}>
      <ContainGallery>
        <FlexImg image={data.Picture}/>

        <FlexTxt>
          <CenterDiv>
            <div>
              <TtlMini>{data.Name}</TtlMini>
              <STtlMini>{data.Adress}</STtlMini>
            </div>
          </CenterDiv>
        </FlexTxt>
      </ContainGallery>
    </a>
  )
}

export default ListGalleries