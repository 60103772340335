import React from 'react'
import emailjs from '@emailjs/browser';
import { Price } from '../../styles/Cart';
import cart from '../../assets/imgs/cart3.png';

const sendEmailConfimation = async (order_number, dataset, user, shippingFee, Price, totalPrice) => {
    const info = "Name: " + user.name + "\n"
                + "Email: " + user.email + "\n"
                + "Address: " + user.address + "\n"
                + "Zip code: " + user.zip + "\n"
                + "City: " + user.city + "\n"
                + "Country: " + user.country + "\n"
                + "Phone: " + user.phone + "\n"
                + "ID: " + (user.idCard || "none") + "\n"
                + "Notes: " + (user.comment || "none") + "\n"

    let products = "<table style='padding:2px 10px; font-weight:normal'><tr><th>Product</th><th>Price</th><th>Quantity</th><th>Total</th></tr>";
    
    dataset.forEach(product => {
        products += "<tr> <th style='min-width:10vw'>" 
        + product.Title 
        + "</th><th style='min-width:10vw'>" 
        + product.Price 
        + "€ </th><th style='min-width:10vw'>"
        + product.Quantity 
        + "</th><th style='min-width:10vw'>" 
        + product.Price*product.Quantity 
        + "€</th>"
    })

    products += "<tr><th>Shipping fee</th><th></th><th></th><th>" + shippingFee + "€</th></tr>"
    products += "<tr><th style='border-top:1px solid black'>Total price</th><th style='border-top:1px solid black'></th><th style='border-top:1px solid black'></th><th style='border-top:1px solid black'>" + totalPrice + "€</th></tr></table>"

    const emailData = {
        order_number,
        to_name: user.name,
        to_email: user.email,
        informations: info,
        items : products,
        //img: "<img src='" + cart +"'/>"
        }

      //e.preventDefault();
  
      await emailjs .send("service_7y26tru","template_z3e0snp", emailData, "Qe1Gw_7p4DJI2zVy2")
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
}

export default sendEmailConfimation