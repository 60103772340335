import { BaseFrame100vh } from "../../styles/Containers"
import bg from "../../assets/imgs/bgShopList.png"
import ResearchFields from "../../components/Desktop/ResearchFields"
import ShopItems from "../../components/Desktop/ShopItems"
import ShopCategories from "../../components/Desktop/ShopCategories"
import ShopDisclaimer from "../../components/Desktop/ShopDisclaimer"
import { useState } from "react"
import HardCodedCategories from "../../components/Mobile/HardCodedCategories"

const ShopListM = () => {
    const [Disclaimer, setDisclaimer] = useState(false) 
    
    const [Query, setQuery] = useState({})
    // console.log(Query)

    const getResearchFields = (fields) => {
    setQuery(fields);
    
  }
  return (
    <BaseFrame100vh img={bg} style={{overflowY:"scroll"}}>
        <div style={{padding:"8vw 5vw 0 5vw"}}>
            <div style={{}}>
                <div style={{paddingLeft:"4vh", paddingBottom:"4vh", transform:"rotate(90deg)", transformOrigin:"left", whiteSpace:"nowrap"}}><ShopCategories styled={{padding:"0px"}}/></div>
                <div style={{paddingLeft:"7vh"}}>
                    {/* <ResearchFields getResearchFields={getResearchFields}/> */}
                    <HardCodedCategories/>
                    <div style={{marginTop:'15px', height:"72vh", overflowY:"scroll" }}>
                        {/* <div onClick={() => setDisclaimer(!Disclaimer)} style={{textAlign:"center"}}><i class="fa-sharp fa-solid fa-chevron-down"></i> Disclaimer <i class="fa-sharp fa-solid fa-chevron-down"></i></div>
                        { Disclaimer && <div><ShopDisclaimer/></div> }
                        <br/> */}
                        <ShopItems style={{}}/>
                    </div>
                </div>
            </div>
        </div>
    </BaseFrame100vh>
  )
}

export default ShopListM