import { Link } from "react-router-dom"
import { CenterDiv } from "../../styles/Containers"
import { BoxTxt } from "../../styles/Shop"

const ShopDisclaimer = () => {
  return (
        <CenterDiv>
            <BoxTxt>
              The Paintings are sold without the frames. You can add the frame at checkout.
              <br/><br/>The standard size of the paintings is 77cm by 55cm.
              <br/><br/>Each painting is accompanied by a certificate of authenticity by the artist.
              <br/><br/><Link to="/shipping">check our shipping policy here</Link>
            </BoxTxt>
          </CenterDiv>
  )
}

export default ShopDisclaimer