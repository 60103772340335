import { FlexMain, FrameGif, DivTopIntoBox, MaxoImage } from '../../../styles/About.js';
import { BaseFrame100vh, CenterHorizontalDiv } from '../../../styles/Containers.js';
import frameGif from '../../../assets/imgs/frame50ms.gif'
import Maxo from '../../../assets/imgs/maxo.jpg'
import { Box, Flex } from 'grid-styled';
import bg from "../../../assets/imgs/about_background_f1.png";


const Description = () => {
  return (
    <BaseFrame100vh style={{backgroundPositionY:"bottom"}} img={bg}>
        <FrameGif src={frameGif}/>
        <CenterHorizontalDiv style={{}}>
            <FlexMain>
                <Box width={6/8}>
                    <DivTopIntoBox>
                            Maxó was born in 1985 in Córdoba, Argentina. His passion for art crossed the border that separated him from Europe and with a one way ticket he came to Barcelona, Spain.
                            <br/><br/>With a dream in mind and his desire to get his hands dirty, in 2009 he opened the doors of what would be his first art gallery located in El Born, specifically in Portal nou 29. It took five years of sweat and a lot of colours for the second gallery to be born, located again in the El Born neighbourhood, in Carders 29. Finally in December 2015, the third gallery opened its doors in the Gothic neighborhood, in Petritxol 18.
                    <br/><br/>In the heart of Barcelona, the city which inspires us with is beauty, diversity and non-stoping madness, we attempt to honor it in our paintings and collages where you will find the most iconic corners of the city: famous buildings, restaurants, graffittis.. but the influence of this magical city doesn’t stop there, we bring it’s essence to all our pieces.

                    </DivTopIntoBox>
                    <Flex>
                        <Box width={2/8}>

                        </Box>
                        <Box width={6/8}>
                        <DivTopIntoBox>
                    Maxó, besides being the director of the different galleries, is a visual artist. His works of art, made in the studio located in the Portal Nou gallery, are made from unlearning, always looking for harmony within the imbalance. Maxó considers his style to be more abstract and experimentative than formalistic. Using various artistic techniques such as acrylic, watercolor or screen printing, he manages to create works that gives a memorable visual experience to the audience when they see the collection as a whole. 
                    <br/><br/>The major motivation that led to the idea of these galleries was the challenge of creating an art gallery, from scratch, in a world where culture is not one of the main concerns. And secondly, Maxó sought to generate a new style of art gallery. A gallery that was close to the client and that when you entered it, you would feel that you were in a magical and unique place with the works being affordable to everyone and not reserved for an elite group.
                    </DivTopIntoBox>
                        </Box>
                    </Flex>
                </Box>
                <Box>
                    <MaxoImage className='white' src={Maxo}/>
                </Box>
            </FlexMain>
        </CenterHorizontalDiv>
    </BaseFrame100vh>
  )
}

export default Description;
