import React from 'react'
import { OverlayDiv } from '../../styles/Overlay'
import { BurgerMenu, ButtonBurger, Cart, Ig } from '../../styles/Mobile/Overlay';
import logo from '../../assets/imgs/logo_header.png';
import logoblink from '../../assets/imgs/logo_blink.png';
import ig from '../../assets/imgs/insta2.png';
import cartimg from '../../assets/imgs/mobile_cart_logo 1.png'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import MiniCart from "../Desktop/MiniCart"
import { useContext } from 'react';
import { CartContext } from '../CartContext';


const OverlayM = () => {

  const [burgerStatus, setBurgerStatus] = useState(false);
  const cartcont = useContext(CartContext)
  const [cart, setcart] = useState(false);

  const location = useLocation().pathname;
  const [NbItem, setNbItem] = useState(cartcont.items.length)

  const [Borgor, setBorgor] = useState({
    width:"1vh",
    height:"5px",
    translate:"0% 0",
    left:"40vw",
    text:false
  })

  useEffect(() => {
    setcart(false);
  }, [location]);
  
  useEffect(() => {
    NbItem < cartcont.items.length
    ? setcart(true)
    : setcart(cart);

    setNbItem(cartcont.items.length)

  }, [cartcont.items])

  const cartHandler = () => {
    setcart(!cart)
  }

  const burgerHandler = () => {
    setBurgerStatus(!burgerStatus)
  }

  useEffect(() => {
    // console.log(burgerStatus)
    if(burgerStatus){
      setBorgor({...Borgor, width:""});
      setBorgor({...Borgor, width:"", left:""});
      setTimeout(function(){
        setBorgor({...Borgor,width:"", height:"", left:""});
      },200);
      setTimeout(function(){
        setBorgor({...Borgor, text:"visible", height:"", width:"", left:""});
      },600);
    }else{
      // setBorgor({...Borgor, text:"hidden"});
      setTimeout(function(){
        setBorgor({...Borgor, text:"hidden",  height:"1vh"});
      },100);
      setTimeout(function(){
        setBorgor({...Borgor, text:"hidden", height:"1vh", width:"5px", left:"40vw"});
      },600);
    }
  
  }, [burgerStatus])

  useEffect(() => {
    // console.log(Borgor)

  }, [Borgor])
  
  

  return (
    <OverlayDiv>
        <MiniCart opened={cart}/>

        {!burgerStatus && <ButtonBurger className="yellow" onClick={() => {
          setBurgerStatus(!burgerStatus);
          }} src={logo}/>}

        {burgerStatus && <ButtonBurger className="yellow" onClick={() => {
        setBurgerStatus(!burgerStatus);
        }} src={logoblink}/>}

          <div style={{display:"flex", justifyContent:"center", textAlign:"center", width:"100vw"}}>
            <Burger Borgor={Borgor} burgerStatus={burgerStatus} burgerHandler={() => setBurgerStatus(!burgerStatus)}/>
          </div>

        <a className='white' target="_blank" href="https://www.instagram.com/galeria_maxo/"><Ig src={ig}/></a>
        
        <Link onClick={cartHandler}>
          <Cart className='white' src={cartimg}/>
        </Link>
    </OverlayDiv>
  )
}

const Burger = ({burgerHandler, burgerStatus, Borgor}) => {
  const location = useLocation().pathname;
  // console.log(location)
  return(
    <BurgerMenu style={{width:Borgor.width, height:Borgor.height, left:Borgor.left}}>
        <div style={{visibility:Borgor.text}} onClick={burgerHandler}>
          <Link className={"yellow" + (location == "/" ? " active" : null)} id="home" onClick={() => window.scrollTo(0, 0)} exact to='/'>HOME</Link>
        </div>
        <div style={{visibility:Borgor.text}} onClick={burgerHandler}>
          <NavLink className="yellow" onClick={() => window.scrollTo(0, 0)} to='/about'>ABOUT</NavLink>
        </div>
        {/* <NavLink to='/artists'>ARTISTS</NavLink> */}
        <div style={{visibility:Borgor.text}} onClick={burgerHandler}>
          <NavLink className="yellow" onClick={() => window.scrollTo(0, 0)} to='/shop'>SHOP</NavLink>
        </div>
        <div style={{visibility:Borgor.text}} onClick={burgerHandler}>
          <NavLink className="yellow" onClick={() => window.scrollTo(0, 0)} to='/contact'>CONTACT</NavLink>
        </div>
        {/* <div style={{visibility:Borgor.text}}>
          <Link to="#"><LangEsp src={esp}/></Link>
          <Link to="#"><LangEng src={eng}/></Link>
        </div> */}
    </BurgerMenu>
  )
}

export default OverlayM