import { MarkDiv, MarkTxt, MarkArrowDiv, FullMark } from '../../styles/Homepage.js';
import Mark from '../../assets/imgs/markthecat.png'
import MarkArrow from '../../assets/imgs/MarkArrow.png'
import MarkSound from '../../assets/sound/Mark.mp3'
import { useState } from 'react';

const JustMark = () => {
  // mark is now Jackson
    const [ MarkName, setMarkName ] = useState(false);
    const audio = new Audio(MarkSound);

    return (
      <FullMark className='cat'>
        <MarkDiv src={Mark} 
          onMouseEnter={() => {setMarkName(true); audio.play()}}
          onMouseLeave={() => setMarkName(false)}/>
        {MarkName && <MarkArrowDiv>
          <img src={MarkArrow} />
          <MarkTxt>I'm Leila</MarkTxt>
          {/* <embed src={MarkSound} loop={false} autostart="true" width="0"
           height="0"></embed> */}
        </MarkArrowDiv>}
      </FullMark>
    )
  }

export default JustMark;