import { Flex } from "grid-styled";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import giftcardbg from "../assets/imgs/giftcardbg.png"
import { BottomLeftImg } from "./Containers";
import { maxDevice } from "./devices";

export const GiftDiv = styled.div`
    width:70vw;
    max-width:780px;
    height:6.5vh;
    background-image: url(${giftcardbg});
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    top: 88%;
    text-align:center;
    color:black;
    font-size: clamp(10px, 5vw, 3vh);
    line-height:5.5vh;
    letter-spacing:4px;

    transition: all 0.3s ease-out;

    @media ${maxDevice.tablet} {
        left:20vw;
        top: 82%;
    }

    &:hover{
        transition: all 0.3s ease-out;
        transform: scale(1.1);
    }
`

export const GiftCard = styled.img`
    position:absolute;
    left:-8vh;
    top:-55%;   //-85%;
    height:13vh  //17vh;

`

export const Dali = styled(BottomLeftImg)`
    opacity:0.5;
`

//Article

export const TitleArt = styled.p`
    font-size:8.3vh;
    margin:2vh 0;
`
export const SubTitleArt = styled.p`
    font-size:2vh;
    margin:2vh 0 5vh 0;
    max-width: 40vw;
`

export const InfoArt = styled.p`
    font-size:5.3vh;
    margin:0.9vh 0;
`

export const ButtonBase = styled.input`
    font-size:2.7vh;
    margin:0.9vh 0;
    font-family:HipelessBrush;
    border:none;
    padding:7px 30px;
    color:white;
    background:black;

    &:hover{
        color:#fef102;
        cursor:pointer;
    }
`

export const ButtonAdd = styled(ButtonBase)`
    background:#c54def;
    &:hover{
        background:#c54def;
        color:#cccccc;
    }
`

export const ButtonBuy = styled(ButtonBase)`
    background:#fef102;
    color : black;
    &:hover{
        background:#cbc001;
        color:#0b213f;
    }
`

export const MainFlex = styled(Flex)`
    padding : 0 5vw;
    height:78%;
    flex-direction: row;
`

export const BoxTxt = styled.div`
    width:100%;
    border:solid white 3px;
    background-color:rgba(0, 0, 0, 0.6);
    font-size:2.2vh;
    text-align:center;
    padding:50px 15px;
    margin:5px;
`

export const DetailsShop = styled.div`
    background-color:rgba(0, 0, 0, 0.6);
    color:white;
    //min-height:100px;
    width:85%;
    text-align:center;
    margin:auto;
    top:-10px;
    position:relative;
    padding:25px 5px;
    border: 1px solid white; 
`

export const BrushedTxt = styled.p`
    font-size:2.2vh;
    margin:0;
    padding:0;
    color: white;
`

export const HillstownTxt = styled(BrushedTxt)`
    font-size:2vh;
    font-family: HillstownSans;
`

export const ButtonShop = styled(Link)`
    background:black;
    font-size : 1.5vh;
    padding: 5px 5px;
    font-family: HillstownSans;
    text-align: center;
    border-radius:0.5vh;

`