import styled from "styled-components";
import green from "../styles/icons/spray_green.png"
import { maxDevice } from "./devices";

export const container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SprayGreen = styled.div`
  cursor: url(${green}), auto;
`

export const CenterDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${props => props.pc}

  @media ${maxDevice.miniLaptop} {
    ${props => props.mob}
  }
`

export const CenterHorizontalDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`

export const zfront = styled.div`
  position: absolute;
  z-index: 1000;
`

export const VideoHP = styled.video`
    min-width:100vw;
    min-height:100vh;
    margin: auto;
    padding: 0;
    text-align:center;
`

export const HpFrame1 = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`
export const BaseFrame = styled.div`
  background-repeat: no-repeat;
  background-size: cover !important;
  width: 100vw;
  position: relative;
  margin: 0;
`

export const BaseFrame100vh = styled(BaseFrame)`
  height: 100vh;
  background-size: 100vw 101%;
  background-image: url(${(props) => props.img}) !important;
  ${(props) => props.stl}
`
export const BaseFrame1078px = styled(BaseFrame)`
  height: 1078px;
  background-image: url(${(props) => props.img}) !important;
`

export const BottomLeftImg = styled.img`
    position:absolute;
    bottom: 0px;
    right: 0px;
    margin:0;
    padding:0;
`

export const Wrapper100pct = styled.div`
  width:100%;
  padding:0;
  margin:0;
  text-align:center;
  display:flex;
  flex-direction:column;

`

export const Img100pct = styled.img`
  width:100%;
  margin:0;
  padding:0;
  z-index:2;
  position:relative;
`
export const SliderDiv = styled.div`
    height: 100vh;
    width:100vw;
    background-image: url(${props=> props.src});
    background-position: center;
    background-size: cover;
`

//object fit; cover