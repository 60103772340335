import { Link } from 'react-router-dom';
import { PolDiv, PolFrame, PolTxt } from '../../styles/Homepage';
import PolImg1 from '../../assets/imgs/Pololaroid_image1.png';
import PolImg2 from '../../assets/imgs/Pololaroid_image2.png';
import PolImg3 from '../../assets/imgs/Pololaroid_image3.png';

const PolaroidMinishop = () => {
    const imgs = {
        "3D PHOTOS": [PolImg1, "/shop/3dphotos/dfe6702b-5659-4685-9191-0881fc31eb30"],
        "Paintings": [PolImg2, "/shop/paintings/fb612412-eff5-406c-8e45-603c9e02c60f"],
        "Collection": [PolImg3, "/shop/collection"]}; //image + link
        
      const listItems = Object.entries(imgs).map(([key, value]) =>  
      
      <PolDiv>
        <Link className='white' to={value[1]}>
          <PolFrame style={{backgroundImage:"url("+value[0]+")"}}></PolFrame>
          <PolTxt>{key}</PolTxt>
        </Link>
      </PolDiv>);

      return listItems;
}

export default PolaroidMinishop