import axios from "axios";

export const addItemsToOrder = async (orderID, dataset) => {    

  console.log(dataset)
  console.log(orderID)
  try{
    for(let i = 0; i < dataset.length; i++){
      const response = await axios.post('productsOrdered/new', {
        ordersId: orderID,
        productId: dataset[i].id,
        quantity: dataset[i].quantity,
        price: dataset[i].price,
        totalPrice : dataset[i].quantity*dataset[i].price,
    });
   }
  }catch(err){
    console.log(err)
  }
}

// const addItemsToOrder = async (orderID, dataset) => {
  
//   let items = [];

//     // dataset.forEach(item => {
//         // items.push({
//         //     ordersId: orderID,
//         //     productId: item.Id,
//         //     quantity: item.Quantity,
//         //     price: item.Price,
//         //     totalPrice : item.totalPrice,
//         // })
//     // });

//     // console.log(items);
    
//   try{


//     for(let i = 0; i < dataset.length; i++){
       
//       console.log("SHIVA-MAXO-PRODUCT")
//        console.log(dataset)
//        console.log(dataset[i].totalPrice)

//       const response = await axios.post('productsOrdered/new', {
//         ordersId: orderID,
//         productId: dataset[i].Id,
//         quantity: dataset[i].Quantity,
//         price: dataset[i].Price,
//         totalPrice : dataset[i].totalPrice,
//     });
//     // console.log(response)
//   }

    

//   }catch(err){
//     console.log(err)
//   }
// }

export default addItemsToOrder