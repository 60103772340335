import bg from '../../../assets/imgs/bgAboutMob.png'
import Maxo from '../../../assets/imgs/MaxoMob.png'
import { DivTopIntoBox, FrameGif } from '../../../styles/About'
import dots4 from '../../../assets/imgs/4drops.png'
import dots3 from '../../../assets/imgs/3drops.png'
import cross from '../../../assets/imgs/plus.png'
import frameGif from '../../../assets/imgs/frame50ms.gif'



const DescriptionM = () => {
  return (
    <div style={{backgroundImage:"url("+bg+")", position:"relative", backgroundRepeat:"no-repeat", backgroundSize:"cover", paddingBottom: "100px"}}>   
        <img style={{position:"absolute", left:"-5vw", top:"50%", width:"30vw", zIndex:3}} src={cross} alt="cross" />

        <div style={{position:"relative"}}>
          <img src={Maxo} style={{paddingTop:"12vh", width:"80vw", margin:"0"}}/>
          <img src={dots4} style={{position:"absolute", bottom:"clamp(-60px,-7vw,-20px)", left:"0", zIndex:"2", width:"40vw"}}/>
        </div>
        
        <DivTopIntoBox style={{paddingBottom:"5vh"}}>
            <span style={{zIndex:100}}>
            <div style={{paddingRight:"2vw"}}>Maxó was born in 1985 in Córdoba, Argentina. His passion for art crossed the border that separated him from Europe and with a one way ticket he came to Barcelona, Spain.</div>
              <br/><br/><div style={{paddingLeft:"7vw"}}>With a dream in mind and his desire to get his hands dirty, in 2009 he opened the doors of what would be his first art gallery located in El Born, specifically in Portal nou 29. It took five years of sweat and a lot of colours for the second gallery to be born, located again in the El Born neighbourhood, in Carders 29. Finally in December 2015, the third gallery opened its doors in the Gothic neighborhood, in Petritxol 18.</div>
              <br/><br/><div style={{paddingRight:"2vw"}}>In the heart of Barcelona, the city which inspires us with is beauty, diversity and non-stoping madness, we attempt to honor it in our paintings and collages where you will find the most iconic corners of the city: famous buildings, restaurants, graffittis.. but the influence of this magical city doesn’t stop there, we bring it’s essence to all our pieces.</div>
              <br/><br/><div style={{paddingLeft:"7vw"}}>Maxó, besides being the director of the different galleries, is a visual artist. His works of art, made in the studio located in the Portal Nou gallery, are made from unlearning, always looking for harmony within the imbalance. Maxó considers his style to be more abstract and experimentative than formalistic. Using various artistic techniques such as acrylic, watercolor or screen printing, he manages to create works that gives a memorable visual experience to the audience when they see the collection as a whole.</div>
              <br/><br/><div style={{paddingRight:"2vw"}}>The major motivation that led to the idea of these galleries was the challenge of creating an art gallery, from scratch, in a world where culture is not one of the main concerns. And secondly, Maxó sought to generate a new style of art gallery. A gallery that was close to the client and that when you entered it, you would feel that you were in a magical and unique place with the works being affordable to everyone and not reserved for an elite group.</div>
            </span>
            <img src={dots3} style={{position:"absolute", bottom:"clamp(-100px,-10vw,-50px)", right:"50Px", zIndex:"2", width:"40vw"}}/>

        </DivTopIntoBox>
        <FrameGif src={frameGif} style={{top:580, rotate:"10deg"}}/>
        {/* <FrameGif src={frameGif} style={{bottom:0, width:"20vh", rotate:"10deg"}}/> */}
    </div>
  )
}

export default DescriptionM