import axios from "axios"

const GetArtworksByArtist = async (id) => {
    try{
        const response = await axios.get(`products/artist/${id}/true/`, {
            type : "cd640a29-311a-45a4-a4bc-f1e99b82ae63"
        });
        let result = [];

        response.data.forEach((element) => 

        result.push({
          "Id": element.id,
          "Picture": (element.artwork.images[0] != undefined) ? window.location.origin + "/static/media/projects/" + element.artwork.id + "/" + element.artwork.images[0].fileName : "",
          "Title": element.artwork.title,
          "SubTitle": element.artwork.description,
          "Size": element.artwork.size,
          "Type": "",
          "Art" : "art",
          "Price": element.price,
          "Quantity" : element.inventory,
          "Option": ""
        }))
          
          return result

    }catch(e){
        console.log(e)
    }
}
export default GetArtworksByArtist