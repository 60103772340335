import Masonry from "react-masonry-css"
import ShopTile from "../../pages/ShopTile";
import { GetArtworkBySerie, getPanelData, getPanelDataAsync } from "../Getters";
import { useParams } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from "react";

const ShopItemsByCatSp = ({filters, id}) => {
    const breakpointColumnsObj = {
        default: 5,
        1386: 4,
        1100: 2,
        480 : 1
      };

      // console.log(id)

      const [Collection, setCollection] = useState("")
      const [Keyword, setKeyword] = useState("")
      const [Artist, setArtist] = useState("")

      const {type} = useParams();

      let q = {
        type: type,
        collection: filters.Collection,
        keywords: filters.Keyword,
        artist: filters.Artist
      } 

      // const panelData = getPanelData(q);
      const [panelData, setpanelData] = useState([])

      const AllArt = async () => {
        setpanelData(await GetArtworkBySerie(id))
      }

      useEffect(() => {
          AllArt()
      }, [id])
      
      // console.log(panelData)

      let generatePanels = new Array();
    
      panelData.forEach(data => {
        generatePanels.push(<ShopTile data={data}/>)
      });

  return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonryGrid"
            columnClassName="masonryGridColumn">
            {generatePanels}
        </Masonry>
  )
}

export default ShopItemsByCatSp