import {
  ContactDiv,
  ContactTitle,
  ContactTxtSm,
  ContainerTxt,
  SprayDiv,
  FlexBot,
  FlexShipping,
} from '../../styles/Contact.js';
import { Box } from 'grid-styled';
import {BaseFrame100vh, CenterDiv} from '../../styles/Containers.js';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import bg from "../../assets/imgs/hp_background_f5.png";
import PageText from './PageText.jsx';
import Social from '../Desktop/Social';
import TxtContact from './TxtContact.jsx';
import TxtShipping from './TxtShipping.jsx';

const ContactFrame = () => {
  const loc = useLocation();
  let txt = "";
  let SprayOpacity = "1";
  let PaddingMain = "0%";
  if(loc.pathname == "/contact" || loc.pathname == "/"){
    txt = TxtContact();

  }else if(loc.pathname == "/shipping"){
    txt = TxtShipping();
    SprayOpacity = 0.6;
    PaddingMain = "5%";
  }

  return (
    <BaseFrame100vh img={bg} id="Contact">
      <div style={{backgroundColor:"rgba(0, 0, 0, 0.4)", width:"100%", height:"100%"}}>
        <SprayDiv op={SprayOpacity}/>
        <FlexBot>
          <CenterDiv>
            <span style={{backgroundColor:"rgba(0, 0, 0, 0.8)", padding:"10px"}}>
              <Link className='yellow' to="/shipping">shipping</Link>
              {/* &nbsp;- terms and conditions - legal info */}
              </span>
          </CenterDiv>
        </FlexBot>
        <ContactDiv pc="top:15vh;" padd={PaddingMain}>
        {txt}
        </ContactDiv>
      </div>
    </BaseFrame100vh>
  )
}


export default ContactFrame;
