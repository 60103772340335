import { useContext, useEffect, useState } from 'react'
import { SubTitle, Price, Bar } from '../../styles/Cart';
import { BaseFrame100vh, CenterDiv } from '../../styles/Containers'
import { DivCart } from '../../styles/Form'
import Breadcrumbs from '../../components/Desktop/Breadcrumbs'
import { getCart, getShippingOpt, getPrice, getUser, getCartAsync } from '../../components/Getters'
import ContinueArrow from '../../components/Desktop/ContinueArrow'
import bg from "../../assets/imgs/bgPayment.png";
import { CartContext } from '../../components/CartContext'
import { Link, Navigate } from 'react-router-dom'
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios'
import RemoveCookie from '../../hooks/RemoveCookie';
import SetCookie from '../../hooks/SetCookie';
import sendEmailConfimation from '../../components/backend/sendEmailConfimation';
import createCustomer from '../../components/backend/createCustomer';
import createOrder from '../../components/backend/createOrder';
import GetCookie from '../../hooks/GetCookie';
import addItemsToOrder from '../../components/backend/addItemsToOrder';
import { cssNumber } from 'jquery';

const Payment = () => {

  const cart = useContext(CartContext);

  // const dataset = getCart(cart.items);

  const [dataset, setdataset] = useState([])

  const AllArt = async () => {
    console.log("Inside All Art")
    let res = await getCartAsync(cart.items)
    console.log(res.data)
    setdataset(await getCartAsync(cart.items))
  }

  useEffect(() => {
      AllArt()
      console.log({dataset})
      console.log("Dataset is above")
  }, [])

  // TODO: Gauthier Change
  const shippingOpt = getShippingOpt({dataset});
  const user = getUser();

  const [ShippingFee, setShippingFee] = useState(0)
  const [TotalPrice, setTotalPrice] = useState(getPrice({dataset}, ShippingFee)[1])

  const [Validate, setValidate] = useState(false)
  const [SpecialPay, setSpecialPay] = useState(false)

  const [SelectedShipping, setSelectedShipping] = useState(false)

  useEffect(() => {
  
    return () => {
      setTotalPrice(getPrice({dataset}, ShippingFee)[1])
    }
  }, [])

const updatePrice = (event) => {
  setShippingFee(event.target.value);
  setSelectedShipping(true)
  // setTotalPrice(getPrice({dataset}, ShippingFee)[1])
  // console.log(ShippingFee)
}

useEffect(() => {
  setTotalPrice(getPrice({dataset}, ShippingFee)[1])
}, [ShippingFee])



const payNow = async token => {

  try {
    const link = SpecialPay ? 'https://mm-backoffice.herokuapp.com/api/7afb8645-2164-4584-9546-3d0730fecb6a/orders/specialPayment' : 'https://mm-backoffice.herokuapp.com/api/7afb8645-2164-4584-9546-3d0730fecb6a/orders/payment/maxo'
    const response = await axios.post(link, {
      amount: Math.floor(TotalPrice * 100),
      token,
    });
    
    if (response.status === 200) {
      // console.log(response)
        const token = response.data.checkId;
        try{
          const customerID = await createCustomer(user);
          const orderID = await createOrder(getPrice({dataset}, 0)[1], parseFloat(ShippingFee), getPrice({dataset}, ShippingFee)[1], user.comment, customerID, token);
          
          // console.log(orderID);

          let i = 0;
          let paintings = 0;
          dataset.forEach(element => {
            if(dataset[i].Art === "paintings"){
            (paintings == 2 && GetCookie("offer25"))
            ? dataset[i].totalPrice = dataset[i].Price * 0.75
            : dataset[i].totalPrice = dataset[i].Price * dataset[i].Quantity;
            i++;
            // console.log(dataset[i])
            }
          });
          
          await addItemsToOrder(orderID[0], dataset);

          RemoveCookie("offer25");
          RemoveCookie("offerShip");
          SetCookie("orderID", orderID[1], 1);

          // TODO: 2nd Gauthier Change
          await sendEmailConfimation(orderID[1], dataset, user,  ShippingFee, getPrice({dataset}, 0)[1], getPrice({dataset}, ShippingFee)[1]);
          
          setValidate(true);
        }catch(e){
          console.log(e)
        }
    }
    // console.log(response)
  } catch (error) {
    console.log(error);
  }
};


  return (
    <BaseFrame100vh img={bg}>
      <CenterDiv style={{position:"relative", top:"-4vh"}}>
        <div>
          <Breadcrumbs number={["3", "Payment details"]}/>
          <DivCart>
            <div>
                {Object.entries(user).map(
                  ([key,value]) =>
                  <SubTitle>{key} : {value}</SubTitle>
                )}
            </div>

            <div style={{paddingBottom:"10px"}}>
                <h5 style={{marginBottom:0, }}>Delivery options:</h5>
                <div style={{}}>
                  {Object.entries(shippingOpt).map(
                    ([key, value]) => 
                    <div style={{padding:"20px 0", marginBottom:"20px", width:"auto"}}>
                    <label style={{textSize:"2vh", position:"absolute", left:"50px", transform:"scale(0.9)"}} for={value} className='styledCheckboxTxtPayMob'>{key}</label>
                    <div style={{position:"absolute", left:"50px",transform:"scale(0.8)"}} className="styledCheckbox">
                        <input type="radio" id={value} name="shipping" value={value} onChange={updatePrice}/>
                        <label for={value}></label>          
                    </div>
                </div>
                  )}
                </div>
            </div>

            <Bar style={{margin:"3vh 0"}}/>

            <div style={{}} width={0.6} id="ShippingOpt">
                <Price style={{textAlign:"right"}}>
                Total : { getPrice({dataset}, ShippingFee)[1] }€
                </Price>
            </div>

            
            {/* {
            dataset.length != 0 && SelectedShipping
            ? <div style={{width:"100%"}}>
                <Link style={{}} onClick={checkout}>
                  <ContinueArrow/>
                </Link>
            </div>
            : ""
            } */}

          {SelectedShipping && <div style={{textAlign:"right"}}>
            <br/>
          <StripeCheckout
          stripeKey={SpecialPay ? "pk_live_51HLo2uJKvp6KGHI6jWo3mlamwb3lNG55CUkwRyAFubNAVBLuaxIlCzcNDvnj1qTtHsKYgKWdTuvixfQdchf5ZvNA00z9tHONGM" : "pk_live_51HLo2uJKvp6KGHI6jWo3mlamwb3lNG55CUkwRyAFubNAVBLuaxIlCzcNDvnj1qTtHsKYgKWdTuvixfQdchf5ZvNA00z9tHONGM"}
            // stripeKey={SpecialPay ? "pk_test_51HD6goKLYxxzx93ZTJlDb2EofObk0afxPteMAcnmDGIFT6DXALzs7actx7vSdU6tV50gy8xFK1BDIDjvCvgbyH4F001GcbxnZk" : "pk_test_51Kmf4vLllq2PEVP0bN6xeo4SkZhThh8vjrgtC8q3FN1P6ZmCFYu43a0gAUzp06ilichhggjKrTLLIbm5Rkuv2uWP00eTXZggxT"}
            label="Pay Now"
            name="Pay With Credit Card"
            //billingAddress
            //shippingAddress
            currency="EUR"
            bitcoin
            amount={TotalPrice*100}
            description={`Purchase on Galeria Maxo for ${TotalPrice}€`}
            token={payNow}
          />
          <br/>
          </div>}

            {/* <Flex>
              <Box style={{maxHeight:"250px", overflowX:"hidden"}} width={1/2} id="CartMini">
                <MiniInfoCart dataset={dataset}/>
              </Box>

              <Box width={1/2} id="ShippingOpt">
                  <Title style={{textAlign:'right', color:'white', fontSize:"90%", marginTop:"50px", marginBottom:"0",}}>DELIVERY OPTIONS:</Title>
                  {Object.entries(shippingOpt).map(
                    ([key, value]) => 
                    <CheckBoxShipping style={{position:"absolute", right:"0px"}} txt={key + " : " + value + "€"} id={value}></CheckBoxShipping>
                  )}
              </Box>
            </Flex>
          
            <Bar style={{marginBottom:"20px"}}/>

            <Flex>
              <Box width={0.4} id="CartMini">
                {Object.entries(user).map(
                  ([key,value]) =>
                  <SubTitle>{value}</SubTitle>
                )}
              </Box>

              <Box style={{}} width={0.6} id="ShippingOpt">
                <Price style={{textAlign:"right"}}>
                Total : { TotalPrice }€
                </Price>
              </Box>
            </Flex> */}

          </DivCart>
          {Validate && <Navigate to="/cart/accepted" replace/>}
        </div>
      </CenterDiv>
    </BaseFrame100vh>
  )
}

export const CheckBoxShipping = (props) => {
  const name = props.id

  const [ShippingFee, setShippingFee] = useState(0)

  const updatePrice = changeEvent => {
    /*setShippingFee(changeEvent.target.value);
    console.log(ShippingFee)*/
  }
  

  return (
  <div style={{padding:"20px 0", marginBottom:"20px", width:"auto"}}>
      <label style={{textSize:"2vh", position:"absolute", left:"50px", transform:"scale(0.9)"}} for={name} className='styledCheckboxTxtPayMob'>{props.txt}</label>
      <div style={{position:"absolute", left:"50px",transform:"scale(0.8)"}} className="styledCheckbox">
          <input type="radio" id={name} name="shipping" onChange={updatePrice(name)}/>
          <label for={name}></label>          
      </div>
  </div>
)
}

export default Payment