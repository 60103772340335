import { OverlayDiv, IgBlock, IgText, NavbarDiv, NavbarTitles, LogoHeader, LogoHeaderBlink, 
  CartImg, 
  LangEsp, 
  LangEng,
  IgPic,
  LangFlag
} from '../../styles/Overlay';
import ig from '../../assets/imgs/insta2.png';
import logo from '../../assets/imgs/logo_header.png';
import logo_blink from '../../assets/imgs/logo_blink_head.png';
import cart from '../../assets/imgs/cart3.png';
import cartFull from '../../assets/imgs/cart5.png';
// import eng from '../../assets/imgs/eng.png';
// import esp from '../../assets/imgs/esp.png';
import eng from '../../assets/imgs/flag_uk.jpg';
import esp from '../../assets/imgs/flag_es.jpg';
import { Box } from 'grid-styled';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MiniCart from './MiniCart';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { CartContext } from '../CartContext';

import { BsInstagram } from 'react-icons/bs';
import paper from '../../assets/sound/Paper2.mp3'
import paper_revert from '../../assets/sound/Paper3.mp3'
import cart_hover from '../../assets/sound/WiiShopChannel.mp3'
import easter from '../../assets/sound/Ricky.mp3'


const Navbar = () => {    
  const [Cart, setCart] = useState(false);
  const cartcont = useContext(CartContext)

  const location = useLocation().pathname;
  
  const [NbItem, setNbItem] = useState(cartcont.items.length)

  useEffect(() => {
    setCart(false);
  }, [location]);

  useEffect(() => {
    NbItem < cartcont.items.length
    ? setCart(true)
    : setCart(Cart);

    setNbItem(cartcont.items.length)

  }, [cartcont.items])

  const CartOpen = new Audio(paper);
  const CartClose = new Audio(paper_revert);
  const CartHover = new Audio(cart_hover);

  return (<NavbarDiv>
  <MiniCart opened={Cart}/>

  <Link style={{position:"static"}} onClick={() => window.scrollTo(0, 0)} to='/'><LogoHeader src={logo}/>
  <LogoHeaderBlink className='yellow' src={logo_blink}/></Link>

  <Link onMouseEnter={() => CartHover.play()} onMouseLeave={() => CartHover.pause()} onClick={() => {Cart ? CartClose.play() : CartOpen.play(); setCart(!Cart); CartHover.pause()}}>
    {
      cartcont.items.length > 0
      ? <CartImg className='yellow' src={cartFull}/>
      : <CartImg className='yellow' src={cart}/>
    }
  </Link>
    <NavbarTitles>
      <Box width={1/17}>

      </Box>
      <Box width={3/16}>
        <NavLink className="yellow" onClick={() => window.scrollTo(0, 0)} to='/about' ><span>ABOUT</span></NavLink>
      </Box>
      {<Box width={3/17}>
        <NavLink to='/artists'>ARTISTS</NavLink>
      </Box>}
      <Box width={3/16}>
        <NavLink className="yellow" onClick={() => window.scrollTo(0, 0)} to='/shop'><span>SHOP</span></NavLink>
      </Box>
      <Box width={3/16}>
        <NavLink className="yellow" onClick={() => window.scrollTo(0, 0)} to='/contact'><span>CONTACT</span></NavLink>
      </Box>
      {/* {<Box width={3/16}>
        <NavLink to='/artists'>ARTISTS</NavLink>
      </Box>} */}
      <Box width={1/16}>
        <div style={{display:"flex", justifyContent:'space-around'}}>
          {/* TODO: make language support */}
          {/* <Link to="#"><LangFlag src={esp}/></Link>
          <Link to="#"><LangFlag src={eng}/></Link> */}
        </div>
      </Box>
      <Box width={2/17}>

      </Box>
    </NavbarTitles>
  </NavbarDiv>)
}

function Ig(){
  return (<table>
    <tbody>
      <a className='yellow' target="_blank" href="https://www.instagram.com/galeria_maxo/">
        <IgBlock>
          <Box>
            <IgPic /*src={ig}*/ />
            {/* <BsInstagram/> */}
          </Box>
          <IgText>FOLLOW US</IgText>
        </IgBlock>
      </a>
    </tbody>
  </table>)
}

function Overlay(){
  // window.addEventListener("load",function() {
  //     setTimeout(function(){
  //         window.scrollTo(0, 1);
  //     }, 10);
  // });
  const Ee = new Audio(easter);

  return (<OverlayDiv>
    <Navbar />
    <Ig />
    <div id="MaximesSurprise"
    onMouseEnter={() => Ee.play()} 
    onMouseLeave={() => Ee.pause()} 
    style={{width:"25px", height:"25px", position:"fixed", bottom:"0px", right:"0px"}}>&emsp;</div>
  </OverlayDiv>)
}

export default Overlay;