import styled from "styled-components";
import spray from "../assets/imgs/spray_smile.png";
import { Flex } from "grid-styled";
import { maxDevice } from "./devices";

export const ContactDiv = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width:45%;
    top:10%;
    right:5%;
    text-align:right;
    padding:30px;
    padding-left:${(props) => props.padd};
    overflow-y: hidden;
    ${props => props.pc}

    @media ${maxDevice.laptopM}{
        width:60%;
        padding:30px 10px;

    }

    @media ${maxDevice.miniLaptop}{
        width:87%;
        padding:30px 10px;

    }
    //height:67%;
`

export const ContainerTxt = styled.div`
    margin-bottom:2vh;
`

export const SprayDiv = styled.div`
    position:absolute;
    height:60%;
    bottom:10%;
    right:40vw;
    //z-index:10;
    content: url(${spray});
    opacity: ${(props) => props.op};
`

export const ContactTitle = styled.p`
    font-size:2.5vh;
    color:#ffeb07;
    line-height:2.5vh;
    padding:0;
    margin:0;
    letter-spacing:4px;

    @media ${maxDevice.laptopM}{
        font-size:1.8vh;
    }
`

export const ContactTxt = styled.p`
    font-size:3.7vh;
    line-height:3.75vh;
    padding:0;
    margin:0;
    letter-spacing:4px;
    @media ${maxDevice.laptopM}{
        font-size:clamp(1px,5vmin,3.5vh);
    }
    a &:hover{
        color: #c54def;
    }
`

export const ContactTxtSm = styled(ContactTxt)`
    font-size:2.5vh;
    line-height:3.2vh;

    a &:hover{
        color: #c54def;
    }

    @media ${maxDevice.laptopM}{
        font-size:2.2vh;
        line-height:2.6vh;
    }
`

export const Icon = styled.img`
    height:7vh;
`

export const Flex2 = styled(Flex)`
    text-align:right;
    font-size:2.5vh;
    line-height:5.5vh;
    letter-spacing:4px;
    display: inline-flex;
    padding:0;
    margin:0;
    @media ${maxDevice.laptopM}{
        font-size:2vh;
    }
`

export const FlexShipping = styled(Flex)`
    text-align:center;
    width: 100%;
    margin:10px 0;
    font-size:2.5vh;
    border: 1px solid white;
    @media ${maxDevice.laptopM}{
        font-size:2vh;   
    }
`

export const FlexBot = styled(Flex2)`
    position: absolute;
    bottom:3%;
    text-align:center;
    margin:auto;
    width:100%;
    font-size:0.75em;
    line-height:0.75em;

    @media ${maxDevice.laptopM}{
        bottom: 15vh;
        display:none;
        z-index:10;
    }
`