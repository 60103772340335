import { Link } from 'react-router-dom';
import { PolDiv, PolFrame, PolTxt } from '../../styles/Homepage';
import PolImg1 from '../../assets/imgs/Pololaroid_image1.png';
import PolImg2 from '../../assets/imgs/Pololaroid_image2.png';
import PolImg3 from '../../assets/imgs/Pololaroid_image3.png';

const PolaroidMiniShopM = () => {
    const imgs = {
        "3D PHOTOS": [PolImg1, "/shop/3dphotos/dfe6702b-5659-4685-9191-0881fc31eb30", "1deg", "5vh", "5vh", 3],
        "Paintings": [PolImg2, "/shop/paintings/fb612412-eff5-406c-8e45-603c9e02c60f", "-15deg", "-5vh", "-0vh", 2],
        "Collection": [PolImg3, "/shop/collection", "7deg", "-14vh", "5vh", 1]};

        const map =  Object.entries(imgs).map(([key, value]) =>  
        <div style={{position:"relative", left:value[4], top:value[3]}}>
        <PolDiv ind={"z-index:"+value[5]} style={{rotate:value[2]}}>
          <Link to={value[1]}>
            <PolFrame style={{ backgroundImage:"url("+value[0]+")"}}></PolFrame>
            <PolTxt>{key}</PolTxt>
          </Link>
        </PolDiv>
        </div>
        );

  return (
    <div>{map}</div>
  )
}

export default PolaroidMiniShopM