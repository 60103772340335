import ContactFrame from '../components/Desktop/ContactFrame';

const Contact = () => {
  return (<div id="contact">
    <ContactFrame>

    </ContactFrame>
  </div>)
}

export default Contact;
