import Description from '../components/Desktop/about/Description';
import Places from '../components/Desktop/about/Places';
import { maxDevice, minDevice } from '../styles/devices';
import MediaQuery from 'react-responsive';
import DescriptionM from '../components/Mobile/about/DescriptionM';
import PlacesM from '../components/Mobile/about/PlacesM';
import { SprayGreen } from '../styles/Containers';

const About = () => {
  return (
    // <SprayGreen>
      <div id="about">
          <MediaQuery query={minDevice.miniLaptop}>
            <Description/>
          </MediaQuery>
          <MediaQuery query={maxDevice.miniLaptop}>
            <DescriptionM/>
          </MediaQuery>
          <MediaQuery query={minDevice.miniLaptop}>
            <Places/>
          </MediaQuery>
          <MediaQuery query={maxDevice.miniLaptop}>
            <PlacesM/>
          </MediaQuery>
          
      </div>

  )
}

export default About;
