import {
  InfoArt, 
  TitleArt,
  ButtonAdd,
  ButtonBuy
} from '../styles/Shop.js';
import { BaseFrame100vh, CenterDiv, CenterHorizontalDiv } from '../styles/Containers';
import bg from "../assets/imgs/shop_background.png"
import { Box, Flex } from 'grid-styled';
import { Link, useParams } from 'react-router-dom';
import {getArticleAsync } from '../components/Getters.jsx';
import E404 from './E404.jsx';
import { useContext, useEffect, useState } from 'react';
import { CartContext } from '../components/CartContext.jsx';
import Magnifier from 'react-magnifier';

function ShopArticle() {
    // const { param } = useParams();

    // const loc = useLocation();

    const {id} = useParams();
    // const {type} = useParams();
    
    const cart = useContext(CartContext);
    // const [Opt, setOpt] = useState(false)
    // const [addedOpt, setaddedOpt] = useState(false)

    // let dataset = getArticle(type, id);
    const [dataset, setdataset] = useState([])

    const Art = async () => {
        setdataset(await getArticleAsync(id))
        if (dataset === undefined){
            return(<E404 />)
        }
      }

      useEffect(() => {
          Art()
          
      }, [])

    // let opt = (dataset.Option != "" ? <CheckBox id={dataset.Id} txt={dataset.Option}/> : <div style={{height:"90px"}}></div>);

    //console.log(sessionStorage.getItem("cart"));

    // console.log(dataset);

  return (
    <BaseFrame100vh img={bg}>
        <CenterHorizontalDiv style={{width:"100vw", overflowY:"hidden"}}>
            <Flex style={{width:"80vw", marginTop:"17vh"}}>
                <Box width={1/3} style={{textAlign:"center"}}>
                    <CenterDiv style={{height:"85%"}}>
                      <Magnifier zoomFactor={2} style={{maxWidth:"100%"}} src={dataset.Picture}/>
                    </CenterDiv>
                </Box>
                <Box width={2/3} /*style={{paddingLeft:"50px",  left:0}}*/>
                    <CenterDiv>
                        <div style={{position:"relative", top: "-8vh", left:0, maxWidth:"85%"}}>
                            <TitleArt>{dataset.Title}</TitleArt>
                            {/* {dataset.SubTitle !== "" && <SubTitleArt>{dataset.SubTitle}</SubTitleArt>} */}
                            <InfoArt>{dataset.Size}</InfoArt>
                            <InfoArt>{dataset.Type}</InfoArt>
                            <InfoArt>{dataset.Price}€</InfoArt>

                            {/* <input name="data" type='hidden' value={JSON.stringify(dataset)}></input> */}
                            
                            {
                                cart.getQuantity(dataset.Id) === 0
                                ?<>
                                    {/* {opt} */}<br/>
                                    {/* {dataset.Option !== '' && dataset.Art === "paintings"
                                    ? addedOpt === false
                                        ? <Link onClick={() => {cart.addCart(0); setaddedOpt(true)}}>Add a frame for 195€</Link>
                                        : "A frame was added to your cart"
                                    : ""}<br/> */}
                                    <ButtonAdd className="purple" onClick={() => cart.addCart(id)} type="button" value="Add to cart"></ButtonAdd>
                                </>
                                : (
                                    dataset.Art === "paintings"
                                    ? <><br/><br/>Already added to cart</>
                                    : <>
                                        <br/><br/>Already added to cart :&nbsp;
                                        <Link onClick={() => cart.rmOneFromCart(dataset.Id)}>-&nbsp;</Link>
                                        {cart.getQuantity(dataset.Id)}
                                        <Link onClick={() => cart.addCart(dataset.Id)}>&nbsp;+</Link>
                                    </>

                        
                        )
                    }

                    <br/>
                    {
                        dataset.Art === ("collection")  || dataset.Art === ("giftcards") || dataset.Art === ("special")
                        ? <Link to="./../../">
                            <ButtonBuy className="yellow" type="button" value="Go Back"></ButtonBuy>
                        </Link>
                        :<Link to="./..">
                            <ButtonBuy className="yellow" type="button" value="Go Back"></ButtonBuy>
                        </Link>
                    }
                    
                        </div>
                    </CenterDiv>

                </Box>
            </Flex>
        </CenterHorizontalDiv>
    </BaseFrame100vh>
  )
}


export default ShopArticle;