import axios from "axios"

const GetAllArtists = async () => {

    try{
        const response = await axios.get(`artists/byProject/e876da54-f013-4809-9907-c06ff5003a05`)

        // console.log(response)
        
        return response.data
    }
    catch(err){
        console.log(err)
    }
}

export default GetAllArtists