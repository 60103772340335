import { Box, Flex } from "grid-styled"
import { useContext } from "react"
import { Infos, Price, SubTitle, Title } from "../../styles/Cart"
import { CartContext } from "../CartContext"
import { Link } from "react-router-dom"
import { countType } from "../Getters"
import GetCookie from "../../hooks/GetCookie"

export const MiniInfoCart = ({dataset}) => {
  const cart = useContext(CartContext)
  let paintings = 0;
    return (
      dataset.map(
        (data) => (
          <Flex style={{padding:"10px 0", fontSize:"75%", lineHeight:"75%"}}>
            <span style={{display:"none"}}>
            {data.Art === "paintings" && countType('paintings', {dataset}) == 2
            ? paintings += 1 
            :""
            }
            </span>
            <Box width={1/6}>
              <img width="100%" src={data.Picture}></img>
            </Box>
            <Box style={{padding:"0px 15px"}} width={4/6}>
              <Title style={{margin:"10px 0px", fontSize:"2.5vh", lineHeight:"105%"}}>
                {data.Title}
              </Title>
              <SubTitle>
                {/* {data.SubTitle} */}
              </SubTitle>
              <Infos>
                {data.Size}
              </Infos>
              <Infos>
                {data.Type}
              </Infos>
            </Box>
            <Box style={{paddingRight:""}} width={1/6}>
              <Price style={{fontSize:"2.5vmin"}}>
              {/* {console.log(paintings)} */}
                { 
                    paintings == 2 && data.Art == "paintings" && GetCookie("offer25")
                    ? <>
                      {data.Price * 0.75}€<br/><br/>
                      <span style={{fontSize:"75%", textDecoration:"line-through", color:"#c54def"}}>
                        {data.Price}€
                      </span>
                    </>
                    : (data.Price * cart.getQuantity(data.Id))+"€"
                  }<br/><br/><br/>
                {/* {data.Art !== "paintings"
                ? <Link onClick={() => cart.rmOneFromCart(data.Id)}>-&nbsp;</Link>
                : ""
                } */}
                {cart.getQuantity(data.Id)}
                {/* {data.Art !== "paintings"
                ? <Link onClick={() => cart.addCart(data.Id)}>&nbsp;+</Link>
                : ""
                } */}
              </Price>
            </Box>
          </Flex>
        ))
  
    )
  }

  export default MiniInfoCart