import ArrowP from "../../assets/imgs/ArrowCart.png"

export const ContinueArrowDiv = ({txt}) => {
  return(
    <div style={{position:"relative", width:"182px"}}>
      <img src={ArrowP}/>
      <div style={{position:"absolute", top:"20px", right:"30px"}}>
        <span>{txt}</span>
      </div>
    </div>
  )
}

export default ContinueArrowDiv