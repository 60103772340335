import bg from "../../../assets/imgs/hp_background_f4.png";
import { BaseFrame1078px, CenterDiv } from "../../../styles/Containers";
import { Flex, Box } from "grid-styled";
import { Yellow } from "../../../styles/Homepage";
import { BARCELOCA } from "../../../styles/Homepage";
import SignBarceloca from '../../../assets/imgs/barceloca.gif';
import ListGalleries from "../ListGalleries";

import P1 from '../../../assets/imgs/Galerias/P1.png';
import Petritxol from '../../../assets/imgs/Galerias/Petritxol.png';
import PortalNou from '../../../assets/imgs/Galerias/PortalNou.png';
import Carders from '../../../assets/imgs/Galerias/Carders.png';
import Olles from '../../../assets/imgs/Galerias/Olles.png';
import Princesa from '../../../assets/imgs/Galerias/Princesa.png';


const FindUsM = () => {
  const frames = 
  [
    {
      "Name": "Galeria Maxó Petrixol",
      "Adress":"Carrer de Petritxol, 18",
      "Adress2":"08002, Barcelona, Spain",
      "Picture":Petritxol,
      "PosX": 10,
      "PosY": 34,
      "PosZ": 10,
      "Tilt": -12,
      "MapsColor":["black", "#ffeb07"],
      "Link":"https://goo.gl/maps/FjA5rY1nrHkX7co56"
    },
    {
      "Name": "Galeria Maxó Pi 1",
      "Adress":"Career Del Pi, 1",
      "Adress2":"08002, Barcelona, Spain",
      "Picture":P1,
      "PosX": 23,
      "PosY": 15,
      "PosZ": 9,
      "Tilt": -12,
      "MapsColor":["black", "#c54def"],
      "Link":"https://goo.gl/maps/JYagAHrNCbBKiUMy9"
    },
    {
      "Name": "Galería Maxó Portal Nou",
      "Adress":"Carrer del Portal Nou, 29",
      "Adress2":"08003, Barcelona, Spain",
      "Picture":PortalNou,
      "PosX": 46,
      "PosY": 51,
      "PosZ": 0,
      "Tilt": 13,
      "MapsColor":["black", "#ffeb07"],
      "Link":"https://goo.gl/maps/Ew4K6xJKA9UokwC87"
    },
    {
      "Name": "Galería Maxó Carders",
      "Adress":"Carrer dels Carders, 29",
      "Adress2":"08003, Barcelona, Spain",
      "Picture":Carders,
      "PosX": 25,
      "PosY": 67,
      "PosZ": 0,
      "Tilt": -3,
      "MapsColor":["white", "black"],
      "Link":"https://goo.gl/maps/Uss5C5w74QbdGPBa6"
    },
    {
      "Name": "Galería Maxó Olles",
      "Adress":"Plaça de les Olles, 1",
      "Adress2":"08003, Barcelona, Spain",
      "Picture":Olles,
      "PosX": 8,
      "PosY": 49,
      "PosZ": 0,
      "Tilt": -20,
      "MapsColor":["black", "#c54def"],
      "Link":"https://goo.gl/maps/8Up9acuhifuQnsEr8"
    },
    {
      "Name": "Galería Maxó Princesa",
      "Adress":"Carrer del Princesa, 15",
      "Adress2":"08003, Barcelona, Spain",
      "Picture":Princesa,
      "PosX": 43,
      "PosY": 30,
      "PosZ": 0,
      "Tilt": -20,
      "MapsColor":["white", "black"],
      "Link":"https://goo.gl/maps/1hiaQYRWnV6yoLjX7"
    }
  ]
  return (
    <BaseFrame1078px img={bg} id="Frame3">
      <Flex>
        <Box width={"40px"}>
          
        </Box>
        <Box width={"100px"} style={{background:"black", height:"1078px", position:"relative"}}>        
          <Flex style={{position: "absolute", rotate:"-90deg", top:"150px", left:"clamp(-75px, -9vw, -95px)", width:"250px",textAlign:"center"}}>
            <Box>
              <div style={{position:"absolute", bottom:"2vw", left:"-700px", fontSize:"2em"}}>Find us in</div>
              <BARCELOCA src={SignBarceloca}/>
              <div style={{position:"relative", bottom:"2vw"}}>
                <Yellow>Open everyday</Yellow>
                <span>12:00 - 20:00</span>
              </div>
            </Box>
          </Flex>
        </Box>
        <Box width={"100%"}>
          <CenterDiv style={{height:"100%"}}>
            <div style={{width:"78%", height:"100%"}}>
            {frames.map((frame) =>
            <ListGalleries data={frame}/>
            )}
            </div>
          </CenterDiv>
        </Box>
      </Flex>
    </BaseFrame1078px>
  )
}

export default FindUsM