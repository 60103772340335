import { BaseFrame100vh } from "../../styles/Containers"
import bg from "../../assets/imgs/bgShopList.png"
import ShopCategories from "../../components/Desktop/ShopCategories"
import ShopDisclaimer from "../../components/Desktop/ShopDisclaimer"
import { useState } from "react"
import ShopItemsByCatSp from "../../components/Desktop/ShopItemsByCatSp"

const ShopListMSp = ({idCat}) => {
    const [Disclaimer, setDisclaimer] = useState(false) 
    
    const [Query, setQuery] = useState({})
    // console.log(Query)

    const getResearchFields = (fields) => {
    setQuery(fields);
    
  }
  return (
    <BaseFrame100vh img={bg} style={{overflowY:"scroll"}}>
        <div style={{padding:"4vw 5vw 0 5vw"}}>
            <div style={{}}>
                <div style={{paddingLeft:"4vh", paddingBottom:"4vh", transform:"rotate(90deg)", transformOrigin:"left", whiteSpace:"nowrap"}}>
                    <ShopCategories styled={{padding:"0px"}}/>
                </div>
                <div style={{paddingLeft:"7vh"}}>
                    {/* <ResearchFields getResearchFields={getResearchFields}/> */}

                    <div style={{marginTop:'15px', height:"83vh", overflowY:"scroll" }}>
                        {/* <div onClick={() => setDisclaimer(!Disclaimer)} style={{textAlign:"center"}}><i class="fa-sharp fa-solid fa-chevron-down"></i> Disclaimer <i class="fa-sharp fa-solid fa-chevron-down"></i></div>
                        { Disclaimer && <div><ShopDisclaimer/></div> }
                        <br/> */}
                        <ShopItemsByCatSp filters={Query} id={idCat}/>
                    </div>
                </div>
            </div>
        </div>
    </BaseFrame100vh>
  )
}

export default ShopListMSp