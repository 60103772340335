import { useEffect } from "react";
import { createContext, useState } from "react";
import GetCookie from "../hooks/GetCookie";
import SetCookie from "../hooks/SetCookie";
import { getArticle } from "./Getters";

export const CartContext = createContext(
    {
        items: [],
        addCart: () => {},
        deleteFromCart: () => {},
        getQuantity : () => {},
        rmOneFromCart: () => {},
        totalCart: () => {},
        emptyCart: () => {}
    }
)

export const CartProvider = ({children}) => {
    // let data = (sessionStorage.getItem("cart") != undefined ? [sessionStorage.getItem("cart")] : [] )
    
    const  [cartProducts, setCartProducts] = useState(() => GetCookie("cart") !== undefined ? JSON.parse(GetCookie("cart")) : []);

    // useEffect(() => {
    //     // GetCookie("cart") !== undefined
    //     // ? setCartProducts(JSON.parse(GetCookie("cart")))
    //     // : setCartProducts([])
    // }, [])
    
    useEffect(() => {
        SetCookie("cart", JSON.stringify(cartProducts), 7)
    }, [cartProducts])
    

    function getQuantity(id) {
        const quantity = cartProducts.find(product => product.id === id)?.quantity
        if (quantity === undefined){
            return 0;
        }
        return quantity;
    }

    function addCart(id) {
        const quantity = getQuantity(id);
        if (quantity === 0){
            setCartProducts(
              [
                ...cartProducts,
                {
                    id:id,
                    quantity:1,
                }
              ]  
            )
        } else {
            setCartProducts(
                cartProducts.map(
                    product => product.id === id 
                    ? {...product, quantity: product.quantity+1}
                    : product
                )
            )
        }
        // sessionStorage.setItem("cart", JSON.stringify(cartProducts));
    }

    function deleteFromCart (id) {
        setCartProducts(
            cartProducts =>
            cartProducts.filter(currentProduct => {
                return currentProduct.id !== id;
            })
        )
        // sessionStorage.setItem("cart", JSON.stringify(cartProducts));
    }

    function rmOneFromCart(id) {
        const quantity = getQuantity(id);

        if(quantity === 1){
            deleteFromCart(id);
        } else {
            setCartProducts(
                cartProducts.map(
                    product => product.id === id 
                    ? {...product, quantity: product.quantity-1}
                    : product
                )
            )
        }
        // sessionStorage.setItem("cart", JSON.stringify(cartProducts));
    }

    function totalCart() {
        let total = 0;
        cartProducts.map((cartItem) => {
            const productData = getArticle(cartItem.id);
            total += (productData.price * productData.quantity)
        })
        return totalCart
    }

    function emptyCart(id) {
        setCartProducts([])
    }


    const contextValue = {
        items: cartProducts,
        addCart,
        deleteFromCart,
        rmOneFromCart,
        getQuantity,
        totalCart,
        emptyCart
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider;