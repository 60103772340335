import styled from "styled-components";
import { Flex, Box } from 'grid-styled';
import { BsInstagram } from "react-icons/bs";


export const OverlayDiv = styled.div`
  z-index : 2000;
  position: fixed;
  margin: 4vh 5vh;
`

export const IgBlock = styled(Flex)`
  position: fixed;
  bottom: 5vh;
  height: 58px;
  margin:0px;
  padding: 0px;
`

export const IgPic = styled(BsInstagram)`
  /* bottom: 4vh; */
  /* height: 6.5vh; */
  margin:0px;
  padding: 0px;
  font-size: 5.5vh;
  line-height: 5.5vh;
`

export const IgText = styled(Box)`
  line-height: 5.75vh;
  vertical-align: middle;
  padding-left: 1vw;
  font-size:3vh;
`
export const NavbarDiv = styled.div`
  position: fixed;
  width: 95vw;
  height: 7vh;
  top:4vh;
  line-height: 7vh;
  background-color: black;
  border-radius: 50px;
`

export const NavbarTitles = styled(Flex)`
  font-size: 4vh;
  text-align: center;
`

export const LogoHeader = styled.img`
  position: absolute;
  top: -2.2vh;
  left: -5px;
  height: 11vh;
`

export const LogoHeaderBlink = styled.img`
  position: absolute;
  top: -2.2vh;
  left: -5px;
  width: 11vh;
  z-index: 3000;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`

export const CartImg = styled.img`
  position: absolute;
  height: 11vh;
  top: -2.2vh;
  right:-5px;
  z-index: 3001;
`
export const LangFlag = styled.img`
  height: 3.2vmin;
  position: relative;
  border-radius: 100%;
`

export const LangEng = styled.img`
  max-width: 30px;
  position: relative;
  padding-left: 51px;
  top:0.51vh;
`

export const LangEsp = styled.img`
  max-width: 27px;
  top:0.5vh;
`