import {BaseFrame100vh, BaseFrame1078px, CenterDiv, CenterHorizontalDiv} from '../../../styles/Containers';
import bg from "../../../assets/imgs/hp_background_f2.png";
import PolaroidMinishop from '../PolaroidMinishop.jsx';
import { minDevice, maxDevice } from '../../../styles/devices';
import MediaQuery from 'react-responsive';
import PolaroidMiniShopM from '../../Mobile/PolaroidMiniShopM';

const MiniShop = () => {
  return (
 
    <BaseFrame100vh stl="background-position:center bottom" img={bg} id="Frame2">
      <CenterDiv>
        <MediaQuery query={minDevice.laptopM}>
          <PolaroidMinishop/>
        </MediaQuery>
        <MediaQuery query={maxDevice.laptopM}>
          <PolaroidMiniShopM/>
        </MediaQuery>
      </CenterDiv>
    </BaseFrame100vh>
  )
}

export default MiniShop;
