import LandingPage from '../components/Desktop/homepage/LandingPage';
import MiniShop from '../components/Desktop/homepage/MiniShop';
import Artists from '../components/Mobile/homepage/ArtistsM';
import ArtistsM from '../components/Desktop/homepage/Artists';
import FindUs from '../components/Desktop/homepage/FindUs';
import ContactFrame from '../components/Desktop/ContactFrame';
import { maxDevice, minDevice } from '../styles/devices';
import MediaQuery from 'react-responsive';
import FindUsM from '../components/Mobile/homepage/FindUsM';

const Home = () => {
  return (<div id="home">
    <LandingPage />
    <MiniShop />
    <MediaQuery query={minDevice.laptopM}>
      <ArtistsM />
    </MediaQuery>
    <MediaQuery query={maxDevice.laptopM}>
      <Artists />
    </MediaQuery>
    

    <MediaQuery query={minDevice.miniLaptop}>
      <FindUs />
    </MediaQuery>
    <MediaQuery query={maxDevice.miniLaptop}>
      <FindUsM />
    </MediaQuery>
    
    <ContactFrame />
  </div>)
}

export default Home;
