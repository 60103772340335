import { BaseFrame100vh, CenterDiv } from '../styles/Containers'
import { DivCartNoPad, DivCartNoOverflow } from '../styles/Form';
import Breadcrumbs from '../components/Desktop/Breadcrumbs';
import ContinueArrow from '../components/Desktop/ContinueArrow';
import { Bar, Marketting, Price } from '../styles/Cart';
import {getCart, getPrice, countType, getSentence, getCartAsync} from '../components/Getters';
import bg from "../assets/imgs/bgCheckout.png";
import ItemList from '../components/Desktop/ItemListCheckout';
import { useContext, useEffect, useState } from 'react';
import { CartContext } from '../components/CartContext';
import { Link } from 'react-router-dom';



const Checkout = () => {


    const cart = useContext(CartContext);
    // const dataset = getCart(cart.items);
    const [dataset, setdataset] = useState([])

    const AllArt = async () => {
      setdataset(await getCartAsync(cart.items))
    }

    useEffect(() => {
        AllArt()
    }, [cart.items])


    let nbPainting = countType("Painting", {dataset});

    let sentence = getSentence(nbPainting);

  return (
    <BaseFrame100vh img={bg}>
      <CenterDiv>
        <div>
          <Breadcrumbs number={["1", "Your Cart"]}/>
            <DivCartNoPad>
              {/* <form method='GET' action="/cart/shipping"> */}
                {
                  dataset.length != 0
                  ? <ContinueArrow className="yellow" linking="/cart/shipping"/>
                  : ""
                }
                <DivCartNoOverflow>
                  <div>
                    {
                      dataset.length == 0
                      ? <span><br/>Your cart is empty, <Link className='purple' to="/shop">go to shop</Link></span>
                      :<ItemList dataset={dataset}/>
                    }
                    
                    <Bar/>
                    <Marketting style={{position:"relative"}}>
                      {sentence}
                    </Marketting>
                    <Price style={{textAlign:"right"}}>
                      <Marketting style={{position:"relative"}}>
                        {getSentence(countType('paintings', {dataset}))}
                      </Marketting>
                      Total : { getPrice({dataset}, 0)[1] }€
                    </Price>
                  </div>
                </DivCartNoOverflow>
              {/* </form> */}
            </DivCartNoPad>
        </div>
      </CenterDiv>
    </BaseFrame100vh>
  )
}


export default Checkout;