import { BaseFrame100vh, BaseFrame1078px, CenterDiv } from '../../../styles/Containers.js';
import bg from "../../../assets/imgs/hp_background_f3.png";
import { SeeMore } from '../../../styles/Homepage.js';
import { Link } from 'react-router-dom';
import Profile from '../artistProfiles/Profile.jsx';
import { getArtists } from '../../Getters.jsx';
import JustMark from '../JustMark.jsx';
import JustTim from '../JustTim.jsx';

const Artists = () => {

  const artists = getArtists();
  const artistPanel = artists.map((artist) => <Profile artist={artist}></Profile>);
          
  return (
    <BaseFrame100vh img={bg} id="Frame3">
      <JustMark/>
      <JustTim/>
      <CenterDiv>
        {artistPanel}
      </CenterDiv>
      <Link className='yellow' to="/shop"><SeeMore>See more...</SeeMore></Link>
    </BaseFrame100vh>
  )
}

export default Artists;
