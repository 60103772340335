import styled from "styled-components";
import { Link } from "react-router-dom";
import { maxDevice } from "./devices";

export const ThanksTxt = styled.p`
    font-size:2.5em;
    color: #C54DEF;
    margin:0 0 20px 0;
`

export const NumberTxt = styled.p`
    font-size:1.3em;
    margin:0 0 20px 0;
`

export const ConfirmEmailTxt = styled.p`
    font-size:0.8em;
    margin:0 0 40px 0;
`

export const LinkCartBase = styled(Link)`
    font-size:0.9em;
    margin:10px 0;
    border:none;
    padding:7px 30px;
    color:white;
    background:black;

    &:hover{
        color:#fef102;
        cursor:pointer;
    }
`

export const LinkPurple = styled(LinkCartBase)`
    background:#c54def;
    &:hover{
        background:#c54def;
        color:#cccccc;
    }
`

export const LinkYellow = styled(LinkCartBase)`
    background:#fef102;
    color : black;
    &:hover{
        background:#cbc001;
        color:#0b213f;
    }

`

//Breadcrumbs

export const BreadcrumbsMain = styled.div`
    width:80vw;
    max-width:600px;
    height:100px;
    margin:20px auto 0;
    
`

export const BreadNumber = styled.div`
    height:50px;
    width:50px;
    margin-bottom:10px;
    text-align:center;
    border-radius:100%;
    background:black;
    border:solid 4px white;
    z-index:10;
    font-size:1.25em;
    ${(props) => props.add}
`

export const BreadNumberActive = styled(BreadNumber)`
    background:#ffeb07;
    border:solid 4px black;
    color:black;
`

export const BreadTextUnder = styled.span`
    position:absolute;
    width:500px;
    color:white;
    font-size:0.75em;
    ${(props) => props.add}
`

// Checkout

export const Title = styled.p`
    font-size:2.75vh;
    color:#ffeb07;
    margin:1vh 0 2.4vh;

    @media ${maxDevice.tablet} {
        /* font-size:clamp(0.3em, 4vw, 1.25em); */
        margin:1vh 0 0px;
    }
`

export const SubTitle = styled.p`
    font-size:1.4vh;
    margin:10px 0;
`

export const Infos = styled.p`
    font-size:.85vh;
    margin:5px 0;
    font-family:HillstownSans;
    letter-spacing:3px;
    bot:0px;
`

export const Price = styled.p`
    font-size:5vh;
    text-align:right;
    margin:10px 0;

    @media ${maxDevice.tablet} {
        font-size:clamp(0.3em, 6vw, 1.25em);
        text-align:left;
        margin:10px 0 0px 0;
    }
`

export const Bar = styled.div`
    height:5px;
    width:100%;
    background:white;
    margin: 4vh 0px;
    border-radius:50px;
`

export const Marketting = styled.p`
    font-size:2vh;
    line-height: 2vh;
    color:#c54def;
    text-align:left;
    margin:10px 0;
    padding:0;
`