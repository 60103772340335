import { ButtonShop, TextIntoImg } from "../../../styles/Homepage";
import shopButton from '../../../assets/imgs/shopmyart.png';

const ShopButton = () => {

  return (
  <ButtonShop>
    <img src={shopButton} />
    <TextIntoImg>
      SHOP MY ART!
    </TextIntoImg>
  </ButtonShop>
  )
}

export default ShopButton;