import { BaseFrame100vh } from "../../styles/Containers"
import bg from "../../assets/imgs/bgShopList.png"
import ResearchFields from "../../components/Desktop/ResearchFields"
import ShopItems from "../../components/Desktop/ShopItems"
import ShopCategories from "../../components/Desktop/ShopCategories"
import ShopDisclaimer from "../../components/Desktop/ShopDisclaimer"
import { useState } from "react"
import ShopItemsByCat from "../../components/Desktop/ShopItemsByCat"
import HardCodedCategories from "../../components/Desktop/HardCodedCategories"
import { useParams } from "react-router-dom"
import { GrMenu } from "react-icons/gr"
import { useEffect } from "react"
import { BurgerMenu } from "../../styles/Mobile/Overlay"

const ShopListMById = () => {

    const [burgerStatus, setBurgerStatus] = useState(false);
    const [Borgor, setBorgor] = useState({
        width:"1vh",
        height:"5px",
        translate:"0% 0",
        left:"40vw",
        text:false
      })

    const burgerHandler = () => {
        setBurgerStatus(!burgerStatus)
    }
    
      useEffect(() => {
        console.log(burgerStatus)
        if(burgerStatus){
          setBorgor({...Borgor, width:""});
          setBorgor({...Borgor, width:"", left:""});
          setTimeout(function(){
            setBorgor({...Borgor,width:"", height:"", left:""});
          },200);
          setTimeout(function(){
            setBorgor({...Borgor, text:"visible", height:"", width:"", left:""});
          },600);
        }else{
          // setBorgor({...Borgor, text:"hidden"});
          setTimeout(function(){
            setBorgor({...Borgor, text:"hidden",  height:"1vh"});
          },100);
          setTimeout(function(){
            setBorgor({...Borgor, text:"hidden", height:"1vh", width:"5px", left:"40vw"});
          },600);
        }
      
      }, [burgerStatus])

    const [Disclaimer, setDisclaimer] = useState(false) 

    let {idCat} = useParams();
    
    const [Query, setQuery] = useState({})
    // console.log(Query)

    const getResearchFields = (fields) => {
    setQuery(fields);

    
  }
  return (
    <BaseFrame100vh img={bg} style={{overflowY:"scroll"}}>
        <div style={{padding:"8vw 5vw 0 5vw"}}>
            <div style={{padding:"0px", margin:0, width:"100vw", display:"flex", justifyContent:"center", position:"absolute", left:0, fontSize:"7vw"}}>
                <div style={{width:"10vw", height:'10vw', background:"#fef102", display:"flex", justifyContent:"center", margin:"auto", borderRadius:"100%", border:"black 3px solid", zIndex:11}}>
                    <GrMenu style={{margin:"auto"}} onClick={() => setBurgerStatus(!burgerStatus)}/>
                </div>
                <BurgerMenu style={{width:Borgor.width, height:Borgor.height, left:Borgor.left, zIndex:"10", top:"6vh"}}>
                    <div style={{visibility:Borgor.text}} onClick={burgerHandler}>
                        <HardCodedCategories/>
                    </div>
                </BurgerMenu>
            </div>
            <div style={{}}>
                <div style={{paddingLeft:"4vh", paddingBottom:"4vh", transform:"rotate(90deg)", transformOrigin:"left", whiteSpace:"nowrap"}}>
                    <ShopCategories styled={{padding:"0px"}}/>
                </div>
                <div style={{paddingLeft:"7vh"}}>
                    {/* <ResearchFields getResearchFields={getResearchFields}/> */}
                    {/* <HardCodedCategories/> */}

                    <div style={{marginTop:'15px', height:"90vh", overflowY:"scroll" }}>
                        {/* <div onClick={() => setDisclaimer(!Disclaimer)} style={{textAlign:"center"}}><i class="fa-sharp fa-solid fa-chevron-down"></i> Disclaimer <i class="fa-sharp fa-solid fa-chevron-down"></i></div>
                        { Disclaimer && <div><ShopDisclaimer/></div> }
                        <br/> */}
                        <ShopItemsByCat filters={Query} id={idCat}/>
                    </div>
                </div>
            </div>
        </div>
    </BaseFrame100vh>
  )
}

export default ShopListMById