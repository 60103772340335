import styled from 'styled-components';

export const ButtonBurger = styled.img `
  text-align:center;
  position:fixed;
  height:12vh;
  bottom:2vh;
  left: 50%;
  margin-left: -6vh;
  z-index:2;
`

export const Ig = styled.img `
    position:fixed;
    left:30px;
    width:9vw;
    max-width:60px;
`

export const Cart = styled.img `
    position:fixed;
    right:30px;
    height:9vw;
    max-height:60px;
    z-index: 3000;
`

export const BurgerMenu = styled.div `
    z-index:1;
    position: fixed;
    background-color: rgba(0,0,0,1);
    bottom: 8vh;
    left:0;
    margin:0 10vw;
    width:80vw;
    text-align: center;
    /* padding: 2vh 0; */
    display:flex;
    flex-direction:column;
    justify-content: space-around;
    font-size:4.5vh;
    height:70vh;
    transition: width 0.5s, height 0.5s, left 0.5s, visibility 0.3s;
    border-radius: 2vh;
`