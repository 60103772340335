import React from 'react'
import { LinkYellow } from '../styles/Cart'
import { BaseFrame100vh, CenterDiv } from '../styles/Containers'
import bg from "../assets/imgs/shop_background.png"

export const E404 = () => {
  return (
    <BaseFrame100vh img={bg}>
        <CenterDiv>
            <div style={{textAlign:"center"}}>
                <p style={{fontSize:"25vh", margin:"10px"}}>404</p>
                <p style={{fontSize:"10vh", margin:"10px"}}>Page not found</p>
                <p style={{fontSize:"3vh", margin:"10px 10px 40px 10px"}}>(You look lost)</p>
                <LinkYellow to="">Homepage</LinkYellow>
            </div>
        </CenterDiv>
    </BaseFrame100vh>
  )
}

export default E404;