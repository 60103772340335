// import video from "../../../assets/videos/Maxó1 compressed.mp4";
import logo from "../../../assets/imgs/main logo.png";
import rotatelogo from "../../../assets/imgs/rotatedevice.gif";
// import {HpFrame1, VideoHP} from '../../../styles/Containers.js';
import { RotateLogo } from "../../../styles/Mobile/Homepage";
import MediaQuery from "react-responsive";
import GallerySlider from "../GallerySlider";


const LandingPage = () => {
  return (
    <GallerySlider />
  )
}

const Logo = () => {
  return (
    <div className="center_div zfront">
      <img src={logo}/>
    </div>
  ) 
}

// const Video = () => {
//   return (
//     <>
//     <MediaQuery orientation="portrait">
//         {/* <RotateLogo src={rotatelogo}/> */}
//     </MediaQuery>
//     <VideoHP autoPlay loop muted>
//       <source src={video} type="video/mp4"/>
//     </VideoHP>
  

//     </>
//   )
// }

export default LandingPage;
