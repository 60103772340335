import { Box, Flex } from 'grid-styled'
import React from 'react'
import Breadcrumbs from '../components/Desktop/Breadcrumbs'
import ContinueArrow from '../components/Desktop/ContinueArrow'
import { getListCountriesDropDown } from '../components/Getters'
import { BaseFrame100vh, CenterDiv } from '../styles/Containers'
import bg from "../assets/imgs/about_background_f2.png";
import { ButtonPillIg, DivCart, Dropdown, Opt, TextAreaField, TextField } from '../styles/Form'
import { maxDevice, minDevice } from '../styles/devices'
import MediaQuery from 'react-responsive';
import { useState } from 'react'
import { useEffect } from 'react'
import SetCookie from '../hooks/SetCookie'
import GetCookie from '../hooks/GetCookie'
import validator from 'validator';


const ShippingForm = () => {
  let ddopt = getListCountriesDropDown();
  let cook = (GetCookie("userdata") != undefined ? JSON.parse(GetCookie("userdata")) : undefined)
  // console.log(cook)
  
  const [Form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    address: "",
    address2:"",
    city: "",
    // province: "",
    zip: "",
    idCard: "",
    comment: ""
  })

  useEffect(() => {
    if(GetCookie("userdata") !== undefined){
      setForm(JSON.parse(GetCookie("userdata")))
    }
  }, [])
  

  const [Completion, setCompletion] = useState(false)

  useEffect(() => {
    // console.log(Form)

    if(Form.name !== (undefined || "") 
      && Form.email !== (undefined || "")
      && validator.isEmail(Form.email)
      && Form.phone !== (undefined || "")
      && validator.isMobilePhone(Form.phone)
      && Form.country !== (undefined || "")
      && Form.address !== (undefined || "")
      && Form.city !== (undefined || "")
      && Form.zip !== (undefined || "")
      ){
        setCompletion(true)
      } else {
        setCompletion(false)
      }

      // SetCookie("userdata", JSON.stringify(Form), 60)
  }, [Form])  

  return (
    <BaseFrame100vh img={bg}>
      <CenterDiv pc='top:-3vh;' mob='top:' style={{position:"relative"}}>
        <div>
          <Breadcrumbs number={["2", "shipping info"]}/>
          <DivCart>
            {/* <form method='get' action="/cart/payment"> */}

              <TextField onChange={(event) => setForm({...Form, "name":event.target.value})} required name="name" type="text" placeholder='Name*' defaultValue={cook != undefined && cook.name != undefined ? cook.name : ""}/>
              <TextField onChange={(event) => setForm({...Form, "email":event.target.value})} required name="email" type="text" placeholder='Email address*' defaultValue={cook != undefined && cook.email != undefined ? cook.email : ""}/>
              <TextField onChange={(event) => setForm({...Form, "phone":event.target.value})} required name="phone" type="tel" placeholder='Phone Number*' pattern="[0-9]{9}" defaultValue={cook != undefined && cook.phone != undefined ? cook.phone : ""}/>
              <Dropdown  onChange={(event) => setForm({...Form, "country":event.target.value})} required name="country" placeholder='country' defaultValue={cook != undefined && cook.country != undefined ? cook.country : ""}>
                <Opt style={{color:"#a8aaac", fontFamily:"HipelessBrush"}} value="">Country*</Opt>
                <Opt value=""></Opt>
                {ddopt}
              </Dropdown>
              <TextField onChange={(event) => setForm({...Form, "address":event.target.value})} required name="address" type="text" placeholder='Address*' defaultValue={cook != undefined && cook.address != undefined ? cook.address : ""}/>
              <TextField onChange={(event) => setForm({...Form, "address2":event.target.value})} name="address2" type="text" placeholder='2nd line' defaultValue={cook != undefined && cook.address2 != undefined ? cook.address2 : ""}/>
              <TextField onChange={(event) => setForm({...Form, "city":event.target.value})} required name="city" type="text" placeholder='City*' defaultValue={cook != undefined && cook.city != undefined ? cook.city : ""}/>
              {/* <TextField onChange={(event) => setForm({...Form, "province":event.target.value})} name="province" type="text" placeholder='Province' defaultValue={cook != undefined && cook.province != undefined ? cook.province : ""}/> */}
              <TextField onChange={(event) => setForm({...Form, "zip":event.target.value})} required name="zip" type="text" placeholder='Zip code*' defaultValue={cook != undefined && cook.zip != undefined ? cook.zip : ""}/>
              <TextField onChange={(event) => setForm({...Form, "idCard":event.target.value})} name="idCard" type="text" placeholder='#ID (recommended)' defaultValue={cook != undefined && cook.idCard != undefined ? cook.idCard : ""}/>
                <TextAreaField placeholder='COMMENT' onChange={(event) => setForm({...Form, "comment":event.target.value})} name="comment">
                  {cook != undefined && cook.comment != undefined ? cook.comment : ""}
                </TextAreaField>
              {/* <Flex style={{width:"100%", margin:"0"}}>
                <Box width={7/10}>
                  <TextField name="discount" type="text" placeholder='Discount code'/>
                </Box>
                <Box style={{textAlign:"right", margin:"0"}} width={3/10}>
                  <ButtonPillIg>Apply</ButtonPillIg>
                </Box>
              </Flex> */}
            {/* </form> */}
              {Completion &&
              <>
              <MediaQuery query={maxDevice.miniLaptop}>
                <div style={{width:"100%"}}>
                  <ContinueArrow onClick={SetCookie('userdata', JSON.stringify(Form), 60)} linking="/cart/payment"/>
                </div>
              </MediaQuery>

              <MediaQuery query={minDevice.miniLaptop}>
                <ContinueArrow onClick={SetCookie('userdata', JSON.stringify(Form), 60)} linking="/cart/payment"/>
              </MediaQuery>
              </>
              }

          </DivCart>
        </div>
      </CenterDiv>
    </BaseFrame100vh>
  )
}

export default ShippingForm