import styled from "styled-components";
import {Flex} from "grid-styled"; 
import PolFrameImg from '../assets/imgs/Polaroid_Frame.png';
import { minDevice, maxDevice } from "./devices";
//Minishop
export const PolDiv = styled.div`
        transition: all 0.3s ease-out;
        position: relative;

        ${(props) => props.ind};
        
               
        &:hover{
            transition: all 0.3s ease-out;
            transform: scale(1.2);
            rotate: 4deg;
            color : black ;
            z-index:7;
            @media ${minDevice.laptopM}{
                margin: 3vw;
            }
        }
`

export const PolFrame = styled.div`
    height: 45vh;
    content: url(${PolFrameImg});
    transition: all 0.3s ease-out;
    margin: 1vw;
    z-index: 10;

    
    background-repeat: no-repeat;
    background-position: top 17% center;
    background-size: 90%;

    @media ${maxDevice.laptopM}{
        height:29vh;

        @media (orientation: landscape){
        height: 34vh;
        }
    }

    


`
export const PolTxt = styled.span`
    position: absolute;
    bottom: clamp(4vh, 6vh, 44px);
    text-align: center;
    width: 100%;
    z-index: 20;
    color: black;
    font-size: 4vh;
    rotate:1.5deg;
    @media ${maxDevice.laptopM}{
        bottom: 5%;
        font-size: 3vh;
        @media (orientation: landscape){
            font-size: 3.5vh;
            bottom:8%;
        }
    }
`

//Artists
export const Circle = styled.div`
    position :relative; 
    width: 300px; 
    height: 300px;
    overflow: hidden;
    word-spacing:0.3125em;
    margin: 20px;
    
`

export const CircularPhoto = styled.img`
    border-radius: 100%;
    position: absolute;
    top: 60px;
    width:220px;
    z-index: 5;
`

export const TextOnCircle = styled.text`
    fill: white;
    letter-spacing: normal;
    
`

export const CircleSvg = styled.svg `
    position: absolute; 
    left: 0;
    width: 100%; 
    height: 600px;
    margin-top: -150px;

    -webkit-animation-name: rotate;
    -moz-animation-name: rotate;
        -ms-animation-name: rotate;
        -o-animation-name: rotate;
            animation-name: rotate;
    -webkit-animation-duration: 20s;
    -moz-animation-duration: 20s;
        -ms-animation-duration: 20s;
        -o-animation-duration: 20s;
            animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
        -ms-animation-timing-function: linear;
        -o-animation-timing-function: linear;
            animation-timing-function: linear;

`

export const TextIntoImg = styled.div`
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translate(0%, -50%);
    font-size: 30px;
    color: black;
`

export const ButtonShop = styled.div`
    position:absolute;
    bottom: -37px;
    visibility: hidden;
    z-index: 20;
`

export const ArtistProfile = styled.div`

transform: scale(0.85);
margin: 0 -15px;

    @media (max-width:1500px){
        transform: scale(0.75);
        margin: 0 -45px;
    }

    @media ${maxDevice.laptopM}{
        transform: scale(0.75);
        margin-bottom: -100px;
        /* margin-bottom: -160px; */
        ${(props) => props.st}
    }

    @media ${maxDevice.tablet}{
        transform: scale(0.65);
    }

    @media ${maxDevice.mobileL}{
        transform: scale(0.55);
    }
    
    &:hover ${Circle}{
        transform: scale(1.2);
        background-color: white;
        border-radius : 100%;
    }

    &:hover ${CircularPhoto}{
        transform: scale(1.2);
    }

    &:hover ${ButtonShop}{
        visibility: visible;
    }

    &:hover ${TextOnCircle}{
        fill: black;
    }
`

export const MarkArrowDiv = styled.div`
    position: absolute;
    top: clamp(70px, 35vw, 150px);
    left: clamp(80px, 20vw, 350px);


    img{
        width: clamp(60px, 10vw, 140px);
    }
`

export const MarkDiv = styled.img`
    position: absolute;
    z-index: 100;
    top:100px;
    width: clamp(150px, 25vw, 380px);

    &:hover{
        rotate: 10deg;
        left: -20px;
        top: 120px;
    }
`   

export const FullMark = styled.div`
    @media ${maxDevice.laptopM}{
        position: relative;
        top: 60vh;
        /* top:550px; */
        /* temp */
    }
    
`

export const MarkTxt = styled.span`
    position: relative;
    top : clamp(30px, 4vw, 40px);
    left: clamp(-200px, -1vw, -20px);
    font-size: clamp(0.6em, 4vw, 1em);

`

export const TimArrowDiv = styled.div`
    position: absolute;
    bottom: clamp(19vh, 20vw, 16vh);
    right:clamp(5px, 1.5vw, 50px);

    img{
        width: clamp(50px, 8vw, 110px);
    }
`

export const TimDiv = styled.img`
    position: absolute;
    z-index: 100;
    bottom:25vh;
    right: 10px;
    width: clamp(120px, 25vw, 220px);
    &:hover{
        rotate: -10deg;
    }
`    

export const TimTxt = styled.span`
    position: relative;
    right: clamp(120px, 20vw, 180px);
    top: clamp(-50px, -5vw, -30px);
    font-size: clamp(0.6em, 4vw, 1em);
`

export const FullTim = styled.div`
    @media ${maxDevice.laptopM}{
        position: relative;
        /* top:600px; */
        top:55vh;
    }
`

export const SeeMore = styled.span`
    position: absolute;
    bottom:50px;
    right:50px;
    line-height: 63px;
` 

//FindUs
export const Map = styled.img`
    width:100%;
    max-width:860px;
    min-width:300px;
`

export const BARCELOCA = styled.img`
    position: absolute;
    right: -100px;
    top: -110px;

    @media ${minDevice.miniLaptop}{
    max-width: 537px;
    width: 65%;
    min-width : 200px;
    }

    @media ${maxDevice.miniLaptop}{
        width: 350px;
        top:-75px;
        right:280px ;
    }
    
`

export const DivMap = styled.div`
    width:100%;
    max-width:860px;
    min-width:300px;
    position: relative;

    @media screen and (max-height:800px){
        scale:0.8
    }

    @media screen and (max-height:700px){
        scale:0.7
    }
`

export const FlexMap = styled(Flex)`
    position:absolute;
    width:100%;
    max-width:860px;
    min-width:300px;
    height: 13%;  
    bottom:0px;
    text-align:center;
    color:white;
    letter-spacing: 4px;
    padding: 0 140px 0 50px;
`

export const Yellow = styled.div`
    color:#ffeb07;
`

export const Pinned = styled.img`
    position:absolute;
    height:36px;
    &:hover{
        transform: translate(0, -17%) scale(1.5) ;
    }
`

export const GalleryDiv = styled.div`
    position:absolute;
    bottom:-15%;
    right:-23%;
    height:85%;
    width:42%;
    border: solid 5px black;
    letter-spacing: normal;
`
export const GalleryBottom = styled.div`
    position:relative;
    bottom:0px;
    height:25%;
    background:white;
    color:black;
    text-align:center;
    padding-top:10px;
    padding-bottom:0;
`

export const GalleryTop = styled.div`
    position:relative;
    height:75%;
    background:black;
    text-align:center;
    overflow:hidden;
`

export const GalleryPic = styled.img`
    position:relative;
    aspect-ratio: initial;
    width:100%;
    overflow:hidden;
    overflow:hidden;
    z-index:10;
    margin: auto;


`
